import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  12: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P69-E12",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E12answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "12",
        title: `
        Choose the correct words.
					`,
        color: "rgb(121,106,175)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "mustn't / don't_have_to / don't_must",
          "should to / need / should",
          "shouldn't / don't_need / needn't",
          "mustn't / don't_need / don't_have_to",
          "must / have / need",
          "has / have / need",
        ],
        answers: ["0-0", "1-10", "2-4", "3-8", "4-0", "5-0"],
        initialValue: {},
      },
      Layout: `
          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">1</span>
                <span>
                You 
                <input id='0' type='Circle' style="letter-spacing: 10px;"/> cheat in exams.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">2</span>
                <span>
                If you need help, you 
                <input id='1' type='Circle' style="letter-spacing: 10px;"/> talk to your teacher.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">3</span>
                <span>
                I’m not thirsty, so I 
                <input id='2' type='Circle' style="letter-spacing: 10px;"/> to stop and have a drink.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">4</span>
                <span>
                You 
 
                <input id='3' type='Circle' style="letter-spacing: 10px;"/> do this extra homework. It’s your choice.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">5</span>
                <span>
                We  
 
                <input id='4' type='Circle' style="letter-spacing: 10px;"/> arrive on time to school every day.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">6</span>
                <span>
                Rosie  
                  
                <input id='5' type='Circle' style="letter-spacing: 10px;"/> to catch the next bus.
                </span>
          </p>


				`,
    },
  },
  13: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P69-E13",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E13answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "13",
        title: `
        Choose the correct words. Then practise with your partner.
					`,
        color: "rgb(121,106,175)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          // borderRadius: '50%',
          fontWeight: "700",
        },
        selectWordStyle: { padding: "none", borderBottom: "2px solid gray" },
        limitSelect: 1,
        listWords: [
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //5
        ],
        answers: ["0-2", "1-0", "2-2", "3-0", "4-4", "5-0"],
        initialValue: {},
      },
      Layout: `
        <div style='display:flex'>
          <b>
            Raul<br>
            Layla<br><br>
            Raul<br>
            Layla<br>
            Raul<br>
            Layla<br>
            Raul<br><br>
            Layla<br>
          </b>

          <div style='width:17cm;margin-left:1cm'>
Hi, Layla. Is everything all right? You 
don’t look very happy.<br>
 I’m really worried about next week’s 
chemistry test. I haven’t really 
understood anything we’ve done. <br>
 Have you <sup>1</sup>.........to anyone 
about it?<br>
 No. Can you give me any <sup>2</sup>.........? 
It’s all such a disaster!<br>
 Listen, <sup>3</sup>.........panic.<br>
 But I know I’m going to fail the test. 
<sup>4</sup>.........should I do?<br>
 You <sup>5</sup>.........to speak to Miss 
Wilson. Teachers are there to help us, 
you know.<br>
 Yes, you’re <sup>6</sup>.......... I’ll go and 
see her right now.
          </div>
        </div>
          <div style='position: relative;'>
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>1</b>  <input id='0'  type='Circle' />
             </div>
      
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>2</b>  <input id='1'  type='Circle' />
             </div>
      
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>3</b>  <input id='2'  type='Circle' />
             </div>
      
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>4</b>  <input id='3'  type='Circle' />
             </div>
      
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>5</b>  <input id='4'  type='Circle' />
             </div>
      
             <div style='display:flex;justify-content:space-between;width:195mm'>
              <b>6</b>  <input id='5'  type='Circle' />
             </div>
             
             <div style=" position: absolute; top: 0px; left: 177px; ">
                 speak<br>
                 advice<br>
                 do<br>
                 What<br>
                 should<br>
                 right<br>
             </div>
             <div style=" position: absolute; top: 0px; left: 464px; ">
                 spoken<br>
                 plan<br>
                 don’t<br>
                 How<br>
                 can<br>
                 true<br>
             </div>
             <div style=" position: absolute; top: 0px; left: 750px; ">
              spoke<br>
              information<br>
              did<br>
              Why<br>
              need<br>
              good<br>
             </div>
        </div>      
          


				`,
    },
  },
  14: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P69-E14",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E14answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "14",
        title: `
        Read the opinion essay and choose the correct words.

					`,
        color: "rgb(121,106,175)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: '-40px',
          borderRadius: "50%",
          fontWeight: "bold",
          // fontSize: '20px',
        },
        selectWordStyle: { padding: 0, border: "2px solid gray" },
        limitSelect: 1,
        listWords: [
          "In_my_opinion / As_a_result", //0
          "All_in_all / Firstly", //1
          "Finally / Secondly", //2
          "Finally / I_feel", //3
          "To_conclude / Secondly", //4
          "All_in_all / Firstly", //5
          "feel / am_feeling", //6
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-0", "6-0"],
        initialValue: {},
      },
      Layout: `
      <div style="margin-left: 100px;position: relative;">
        <img style='height:18cm' src="img/FriendsPlus/Page69/E14/1.jpg"/>
      
        <div style="position: absolute;top:90px;left:38px;width:195mm">
          Some students say that tests don’t help them learn. 
          As soon as they pass a test, they forget what they’ve 
          learned. Is it really as simple as this? 
          <sup>1</sup><input id='0' type='Circle'/>, tests are necessary for 
          several reasons. <sup>2</sup><input id='1' type='Circle'/>, they motivate 
          students to study. Being successful at school is 
          important for many young people, so they work hard 
          to get good marks. <sup>3</sup><input id='2' type='Circle'/>, tests allow 
          students to check how much they know and which 
          things they still need to work on. During the test, 
          students have to count on their own memory and 
          their own abilities. <sup>4</sup><input id='3' type='Circle'/>, I believe that tests 
          teach essential life skills which will be useful in the 
          future. For example, before and during a test, students 
          need to plan their work, manage their time, concentrate 
          on the task and deal with stress. <br>
          <sup>5</sup><input id='4' type='Circle'/>, tests can be helpful in many 
          different ways. <sup>6</sup><input id='5' type='Circle'/>, <sup>7</sup><input id='6' type='Circle'/>
          that they are one of the most important parts of 
          school life.
        </div>


      </div>
      

				`,
    },
  },
};

export default json;
