import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P4-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page4/E1/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 35,
    titleQuestion: [
      {
        num: "1",
        title: "Work with a partner and do the quiz.",
        color: "rgb(3, 103, 181)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; justify-content: center; align-items: center;'>
            <div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
              max-width: 420px; padding: 10px 30px; border-radius: 20px; border: 1px solid rgb(3, 103, 181);'>
              <span>app</span><span>craze</span><span>fad</span><span>follower</span>
              <span>gadget</span><span>post (<i>n, v</i>)</span><span>social media</span>
            </div>
					</div>
              <div><img src='img/FriendsPlus/Page4/E1/a.jpg'  /></div>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P4-E2",
    audio: "Audios/1.06.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page4/E2/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 40,
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.06'></headphone > Listen and check your answers to the quiz. Which answer surprised you the most?`,
        color: "rgb(3, 103, 181)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; justify-content: center; align-items: center;'>
            <div style='display: flex; gap: 20px; flex-wrap: wrap;'>
              <div style='display: flex; gap: 10px;'><span>1</span>#</div>
              <div style='display: flex; gap: 10px;'><span>2</span>#</div>
              <div style='display: flex; gap: 10px;'><span>3</span>#</div>
              <div style='display: flex; gap: 10px;'><span>4</span>#</div>
              <div style='display: flex; gap: 10px;'><span>5</span>#</div>
              <div style='display: flex; gap: 10px;'><span>6</span>#</div>
            </div>
						</div>
            <div><img src='img/FriendsPlus/Page4/E1/a.jpg'  /></div>
        `,
        answer: ["b", "b", "a", "a", "a", "a"],
      },
    ],
  },
};

export default json;
