import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E1",
    audio: "Audios/1.36.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page44/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    hintBox: [{
      src: ["cheaper", "expensive", "less", "mum", "nice", "small"],
      width: "",
      borderColor: "purple"
    }],
    titleQuestion: [
      {
        num: "1",

        title: `
			<headphone name="1.36"></headphone >
			Complete the dialogue with the words in the box. Then watch or listen and check. Which pendant does Sammy buy?
					`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex;margin-bottom:10px'>
          <b>
            Sammy<br><br>
            Grace<br>
            Sammy<br>
            Grace<br><br>
            Sammy<br>
            Grace<br>
            Sammy<br><br>
            Grace<br>
          </b>
          <div style='margin-left:20px;width:17cm'>
          I’m looking for jewellery for my 
          <sup>1</sup># . What do you think of this 
          pendant? It’s real gold.
          <br> Oh, it’s nice, but it’s quite <sup>2</sup># .
          <br> I like the size of it, but it’s a bit too <sup>3</sup># .
          <br> Wow! It’s, like, £70! I mean, it’s not as <sup>4</sup># as that silver one.
          <br> Oh yes, I much prefer that one. It’s 
          Mum’s style.
          <br> Which one is <sup>5</sup># ?
          <br> OK, let’s see. Right, the silver one is much 
          <sup>6</sup># expensive. It’s only £30.
          <br> Well, get that one then.
          </div>
          <img src="img/FriendsPlus/Page44/E1/1.jpg" style='height:8cm'/>
        </div>
        <div>
          Which pendant does Sammy buy?<br>
          <input id='6' width='500px'></input>
        </div>
        
					`,
        answer: [
          "mum",
          "small",
          "expensive",
          "nice",
          "cheaper",
          "less",
          "He buys the silver one.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E2",
    audio: "Audios/1.36.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page44/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
		<headphone name="1.36"></headphone >
		Cover the dialogue and read the Key Phrases. Choose the words that Sammy and Grace use. Watch or listen again and check.
					`,
        color: "rgb(220,30,116)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "this / these",
          "it / those",
          "a_bit / much",
          "as / so",
          "that_one / those_ones",
          "better / cheaper",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-4"],
        initialValue: {},
      },
      Layout: `
		<div><img src="img/FriendsPlus/Page44/13.jpg" /></div>
		<h1 style="margin-top:20px">ANSWERS</h1>
		<div style="display: flex; flex-wrap: wrap">
			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold; margin-right:10px">1</span>
					What do you think of
				</span>
				<sup style="font-weight: bold;">1 </sup>
				<input id='0' type='Circle' style="letter-spacing: 10px;"/> 
				<span>...?</span>
			</div>


			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold;margin-right:10px">2</span>
					I like the … of
				</span>
				<sup style="font-weight: bold;">2 </sup>
				<input id='1' type='Circle' style="letter-spacing: 10px;"/>
				<span>.</span>	
			</div>


			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold; margin-right:10px">3</span>
					It's
				</span>
				<sup style="font-weight: bold;">3 </sup>
				<input id='2' type='Circle' style="letter-spacing: 10px;"/>
				<span> too...</span>	
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold; margin-right:10px">4</span>
					It's (not)
				</span>
				<sup style="font-weight: bold;">4 </sup>
				<input id='3' type='Circle' style="letter-spacing: 10px;"/>
				<span>...as...</span>	
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold; margin-right:10px">5</span>
					I much prefer
				</span>
				<sup style="font-weight: bold;">5 </sup>
				<input id='4' type='Circle' style="letter-spacing: 10px;"/>
			</div>

			<div style='width: 1000px;margin: 10px 10px;'>
				<span style="margin-right: 10px;">
					<span style="font-weight:bold; margin-right:10px">6</span>
					Which one is
				</span>
				<sup style="font-weight: bold;">6 </sup>
				<input id='5' type='Circle' style="letter-spacing: 10px;"/>
				<span>?</span>	
			</div>

		</div>
				

			
				`,
    },
  },
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Work in pairs. Practise the dialogue
		`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div class="container">
			<div><img src="img/FriendsPlus/Page44/2.jpg" width="1200px" height="600px"/></div>
		</div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page44/key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
		We can make comparatives with adverbs. Choose the correct option in the following examples.
					`,
        color: "rgb(220,30,116)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: ["more / much", "more_beautifully / more_beautiful"],
        answers: ["0-4", "1-0"],
        initialValue: {},
      },
      Layout: `
	  	<div style='width: 1000px;margin: 10px 10px;'>
			<span style="margin-right: 10px;">
				<span style="font-weight:bold; margin-right:10px">1</span>
				Her bag costs  
			</span>
			<input id='0' type='Circle' style="letter-spacing: 10px;"/> 
			<span> than mine</span>
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="margin-right: 10px;">
				<span style="font-weight:bold; margin-right:10px">2</span>
				This box is carved
   
			</span>
			<input id='1' type='Circle' style="letter-spacing: 10px;"/> 
			<span> than that one.</span>
		</div>
				

			
				`,
    },
  },
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Look at the two objects recycled from magazines. Use comparative adverbs to describe and compare them.
		`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div class="container">
		<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page44/14.jpg" width="1000px" height="500px"/></div>

			
		</div>


        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P44-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
		Work in pairs. You want to buy a present for a friend. Compare these products and decide which one you'll buy.
		Use the key phrases and comparatives to help you. 
		`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div class="container">
			<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page44/13.jpg" width="1000px" height="500px"/>
			</div>
			
			<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page44/15.jpg" width="1000px" height="700px"/>
			</div>
		</div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
