import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E1",
    audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E1/Key/answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: [
          `around`,
          `down`,
          `off`,
          `on`,
          `over`,
          `<s>through</s>`,
          `under`,
          `up`,
        ],
        borderColor: "rgb(245, 128, 37)",
        width: 380,
      },
    ],
    inputSize: 390,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					<headphone name='1.08'></headphone > 
					Look at pictures A–D below. Match 
					the prepositions in the box with the actions. 
					Say what each person is doing. Then listen 
					and check.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 30px; display: flex; gap: 50px;'>
						<div style='position: relative;'>
							<div style='width: 400px;'><img src='img/FriendsPlus/Page34/E1/1.jpg' width='100%' /></div>
							<div style='position: absolute; top: 187px; left: 27px;'><input id='0' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 161px; left: 257px;'><input id='1' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 191px; left: 245px;'><input id='2' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 398px; left: 34px;'><input id='3' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 428px; left: 41px;'><input id='4' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 398px; left: 252px;'><input id='5' width='150px' border-bottom='none' /></div>
							<div style='position: absolute; top: 428px; left: 237px;'><input id='6' width='150px' border-bottom='none' /></div>
						</div>

						<div style='display: flex; gap: 20px; flex-direction: column;
							padding: 20px; border-radius: 10px; border: 2px solid rgb(28, 192, 217);'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>A</span>
								<div>#<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>B</span>
								<div>#<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>C</span>
								<div>#<br />#</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>D</span>
								<div>#<br />#</div>
							</div>
						</div>
					</div>
        `,
        answer: [
          `around`,
          `up`,
          `down`,
          `over`,
          `on`,
          `off`,
          `under`,
          `He's walking through the forest.`,
          `She's walking around the forest.`,
          `He's pushing the snowball up the hill.`,
          `She's skiing down the hill.`,
          `He's jumping over the puddle.`,
          `He's stepping on a rock.`,
          `He's jumping off the fence.`,
          `He's going under the garage door.`,
        ],
      },
    ],
  },
  2: {
  	unit: 'Unit 3',
  	id: 'FP8-SB-demo-2023-U3-P34-E2',
  	exerciseKey: 'img/FriendsPlus/Page34/E2/Key/answerKey.png',
  	component: Circle_Write,
  	titleQuestion: [
  		{
  			num: '2',
  			title: `
  				Read <i>Your Top Five Extreme Adventures</i> and
  				check the meaning of the sports verbs in
  				<span style='color: rgb(0, 173, 238);'>blue</span>. Then choose the correct prepositions.
  			`,
  			color: 'rgb(245, 128, 37)',
  		},
  	],
  	question: {
  		Write: {
  			inputStyle: {},
  			answers: [],
  			initialValue: [],
  		},
    

  		Circle: {
  			initialWordStyle: {
  				padding: '-40px',
  				fontWeight: 500,
  				fontSize: 22,
  				fontStyle: 'italic',
  				color: '',
  				borderRadius: '50%',
  			},
  			selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
  			limitSelect: 1,
  			listWordsStyle: {},
  			listWords: [
  				`around / on`,
  				`through / up `,
  				`over / off`,
  				`none / off`,
  				`down / under`,
  			],
  			answers: ['0-0', '1-4', '2-4','3-0','4-0'],
  			initialValue: [],
  		},
  		Layout: `
  			<div style='position: relative;font-size:13px;'>
  				<div><img src='img/FriendsPlus/Page34/E2/1.jpg' width='95%' /></div>
  				<div style='position: absolute; top: 86px; left: 834px;'><input id='0' type='Circle' /></div>
  				<div style='position: absolute; top: 232px; left: 642px;'><input id='1' type='Circle' /></div>
  				<div style='position: absolute; top: 320px; left: 632px;'><input id='2' type='Circle' /></div>
  				<div style='position: absolute; top: 380px; left: 625px;'><input id='3' type='Circle' /></div>
  				<div style='position: absolute; top: 442px; left: 636px;'><input id='4' type='Circle' /></div>
  			</div>
  			`,
  	},
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E3",
    audio: "Audios/1.28.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E3/Key/answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: [
          `cycling`,
          `mountain climbing`,
          `sailing`,
          `skydiving`,
          `surfing`,
        ],
        borderColor: "rgb(245, 128, 37)",
        width: 700,
      },
    ],
    inputSize: 200,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					<headphone name='1.28'></headphone > 
					Listen to a TV programme about the achievements in exercise 2. 
					Put the sports that are mentioned in the correct order.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>#</div>
						</div>
					</div>
        `,
        answer: [
          `skydiving`,
          `cycling`,
          `sailing`,
          `mountain climbing`,
          `surfing`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E5",
    component: T6v2,
    hideBtnFooter: true,
    textareaStyle: { width: 730 },
    inputSize: 620,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Read the Study Strategy. Close your book and 
					make a note of three things you learned from 
					the TV programme. Compare your answers 
					with a partner's.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page34/E5/1.jpg' width='50%' /></div>
					<div><textarea id="0" rows="10"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E6",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 250,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
					Work with a partner. Ask and 
					answer questions about adventure activities. 
					Use the ideas below.
				`,
        color: "rgb(245, 128, 37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
	
					<div style='display: flex; gap: 40px;'>
						<div>
							<div>Do you like the look of … ?</div>
							<div>Would you like to try … ?</div>
						</div>
						<div>
							<div>Have you ever tried … ?</div>
							<div>What do you think of … ?</div>
						</div>
					</div>
				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E4",
    audio: "Audios/1.28.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E4/Key/answerKey.png",
    component: T6v2,
    inputSize: 620,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name='1.28'></headphone > 
					Listen again and answer the questions.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>What did Éric Barone cycle over?<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>How old was Laura Dekker when she finished sailing around the world?<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>Has anyone younger beaten her record?<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>Where were the teenage Everest climbers from?<br />#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>According to the presenter, which person has the most votes?<br />#</div>
						</div>
					</div>
        `,
        answer: [
          `He cycled over a volcano.`,
          `She was sixteen.`,
          `No`,
          `The boy was from the USA, and the girl was from India.`,
          `The surfer, Garrett McNamara, has the most votes.`,
        ],
      },
    ],
  },
};

export default json;
