import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	12: {
		unit: 'Unit 4',
		id: 'FP8-SB-demo-2023-U4-P49-E12',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page49/key/E12answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '12',
				title: `
			Choose the correct words.
							`,
				color: 'rgb(121,106,175)',
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 100,
					textAlign: 'center',
					fontSize: 20,
					color: 'black',
					maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					color: '',
					borderRadius: '50%',
					fontWeight: '700',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWords: [
					"am / aren't",
					"have / haven't",
					'it / that?',
					"won't / will",
					"shouldn't / don't",
					"weren't / were",
				],
				answers: ['0-4', '1-4', '2-0', '3-4', '4-0', '5-4'],
				initialValue: {},
			},
			Layout: `
				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">1 </span>
					I am taller than you, <input id='0' type='Circle' style="letter-spacing: 10px;"/> I?  
				</div>

				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">2 </span>
					You've got a new bike, <input id='1' type='Circle' style="letter-spacing: 10px;"/> you?  
				</div>

				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">3 </span>
					That's an amazing picture, isn't <input id='2' type='Circle' style="letter-spacing: 10px;"/>  
				</div>

				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">4 </span>
					We won't have much time tomorrow, <input id='3' type='Circle' style="letter-spacing: 10px;"/> we?
				</div>

				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">5 </span>
					They should work harder, <input id='4' type='Circle' style="letter-spacing: 10px;"/> they?
				</div>

				<div style='margin: 10px 10px;'>
					<span style="font-weight: bold;">6 </span>
					We weren't playing video games all day, <input id='5' type='Circle' style="letter-spacing: 10px;"/> we?
				</div>
	
				
		
				
					
						`,
		},
	},
	13: {
		unit: 'Unit 4',
		id: 'FP8-SB-demo-2023-U4-P49-E13',
		audio: '',

		video: '',
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page49/key/E13answerKey.png',
		component: T6v2,
		// maxLength: 6,
		inputSize: 150,
		maxLength: 5,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			//   textAlign: 'center',
			fontSize: '24px',
			fontWeight: 'bold',
		},
		titleQuestion: [
			{
				num: '13',

				title: `
		Complete the questions with question tags.
							`,
				color: 'rgb(121,106,175)',
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="margin-top: 10px;">
				<div>
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					1
					</span>
					<span style="font-size: 25px">They can't save more resources, #</span> ?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					2
					</span>
					<span style="font-size: 25px"> Maria is leaving soon, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					3
					</span>
					<span style="font-size: 25px"> They haven't solved the problem, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					4
					</span>
					<span style="font-size: 25px"> Jamie loves studying history, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					5
					</span>
					<span style="font-size: 25px"> You aren't having fun, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					6
					</span>
					<span style="font-size: 25px">The weather will be nice later, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					7
					</span>
					<span style="font-size: 25px"> We don't recycle glass, #</span>?
				</div>

				<div style="margin-top: 10px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					8
					</span>
					<span style="font-size: 25px">I won't need to tidy up, #</span>?
				</div>
				
			</div>
		</div>
							`,
				answer: [
					'can they',
					"isn't she",
					'have they',
					"doesn't he",
					'are you',
					"won't it",
					'do we',
					'will I',
				],
			},
		],
	},
	14: {
		unit: 'Unit 4',
		id: 'FP8-SB-demo-2023-U4-P49-E14',
		audio: '',

		video: '',
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page49/key/E14answerKey.png',
		component: T6v2,
		// maxLength: 6,
		inputSize: 120,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			textAlign: 'center',
			fontSize: '24px',
			fontWeight: 'bold',
		},
		titleQuestion: [
			{
				num: '14',

				title: `
        Complete the dialogue with the words. Then practise with your partner. There are two
		extra words.
							`,
				color: 'rgb(121,106,175)',
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold"as</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">cheaper</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">less</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">like</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">more</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">much</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">think</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">want</p>
			</div>

			<div style="display: flex; margin-top:30px">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Bethany</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">
					I'm looking for a present for Dad. What do you <sup>1</sup> #  of this
					shower-power radio?</p>

					
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Ollie</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">
					It looks cool. But it isn't <sup>2</sup> #  nice as this one.</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Bethany</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">
					I <sup>3</sup> #  prefer this one, too. It's far more modern.</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Ollie</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">
					I <sup>4</sup> #  the colour of it. Dad'll love it, too.</p>
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Bethany</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">Which one is <sup>5</sup> #?</p>
					
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Ollie</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">This one's much <sup>6</sup> # expensive. It's only £30.</p>
					
				</div>
			</div>

			<div style="display: flex;">
				<div class="col-2" style=";margin-right:-50px">
					<p style="font-size: 25px; font-weight: bold;">Bethany</p>
				</div>
				<div class="col-10">
					<p style="font-size: 25px">Wow! Let's get that one, then.</p>
					
				</div>
			</div>



		</div>
    
		
							`,
				answer: ['think', 'as', 'much', 'like', 'cheaper', 'less'],
			},
		],
	},
	15: {
		unit: 'Unit 4',
		id: 'FP8-SB-demo-2023-U4-P49-E15',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page49/key/E15answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '15',
				title: `
		Choose the correct words.
							`,
				color: 'rgb(121,106,175)',
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 100,
					textAlign: 'center',
					fontSize: 20,
					color: 'black',
					maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					color: '',
					borderRadius: '50%',
					//   fontWeight: '700',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWords: [
					'so_that / to / in_case',
					'advert / advantage / disadvantage',
					'does / makes / looks',
					'is / makes / finds',
					'so / to / by',
					'with_it / about_it / of_it',
					'in_order_to / in_case / so_that',
					'in_order_to / so_that / in_case',
					"You've / You're / You'll",
				],

				answers: [
					'0-4',
					'1-8',
					'2-0',
					'3-8',
					'4-4',
					'5-0',
					'6-0',
					'7-4',
					'8-8',
				],
				initialValue: {},
			},
			Layout: `
				<div style='display:flex'>
					<img src="img/FriendsPlus/Page49/8.jpg"  />
	  				<div>
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">1 </span>
							<input id='0' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">2 </span>
							<input id='1' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">3 </span>
							<input id='2' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">4 </span>
							<input id='3' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">5 </span>
							<input id='4' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">6 </span>
							<input id='5' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">7 </span>
							<input id='6' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">8 </span>
							<input id='7' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
	
						<div style='margin: 10px 10px;'>
							<span style="font-weight: bold;margin-right:10px">9 </span>
							<input id='8' type='Circle' style="letter-spacing: 10px;"/> 
						</div>
					</div>

					
				</div>
					
						`,
		},
	},
};

export default json;
