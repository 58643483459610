import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/key/E1answerKey.png",
    // recorder: true,

    component: T6v2,
    // maxLength: 1,
    inputSize: 450,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "1",
        title: `
			Read these sentences and find out what
			tense the bold verbs are.
						`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style="display: flex; flex-wrap: wrap;font-size: 25px">
					<span style="font-weight: bold; margin-right: 10px">1</span>
					<span>If more people <b>were</b> like Robert, the world
					would be a better place</span>
				</div>
	
				<div style="display: flex; flex-wrap: wrap;font-size: 25px">
					<span style="font-weight: bold; margin-right: 10px">2</span>
					<span>If those people <b>had</b> jobs, they wouldn’t
					need to sell baby gorillas</span>
				</div>
				
				<div style="display: flex; flex-wrap: wrap;font-size: 25px">
					<span style="font-weight: bold; margin-right: 10px">3</span>
					<span>If I <b>had</b> to choose just one person,
					it’d be a grandmother …</span>
				</div>
	
				<div style="display: flex; flex-wrap: wrap;font-size: 25px">
					<span style="font-weight: bold; margin-right: 10px">4</span>
					<span>If I <b>were</b> you, I’d start thinking of
					other ideas.</span>
				</div>
        <div style='display:flex'>
          <img style='height:10cm ;margin-right:5mm' src="img/FriendsPlus/Page75/16.jpg"/>
          <div>
            <u style='font-weight:bold;font-style:italic'>Answer:</u><br>
            #
          </div>
        </div>

						`,
        answer: ["The verbs in bold are all past simple."],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
        Study the rule and choose the correct options
					`,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 30,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
          fontSize: "32px",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: ["present / past", "likely / unlikely", "result / location"],

        answers: ["0-4", "1-4", "2-0"],
        initialValue: {},
      },
      Layout: `
	  <div style="position: relative; ">
	  	<img src="img/FriendsPlus/Page75/17.jpg" width="800px" />

		  <div style="position: absolute;top:91px;left:219px">
		  	<input id='0' type='Circle' style="letter-spacing: 10px;"/>
		  </div>

		  <div style="position: absolute;top:132px;left:111px">
		  	<input id='1' type='Circle' style="letter-spacing: 10px;"/>
		  </div>

		  <div style="position: absolute;top:211px;left:28px">
		  	<input id='2' type='Circle' style="letter-spacing: 10px;"/>
		  </div>
		
	  </div>
	  <div style="margin-left: 50px">
      <img src="img/FriendsPlus/Page75/18.jpg" />
    </div>
          

          

				`,
    },
  },
  3: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E3",
    // audio: "Audios/2.27.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page75/key/E3answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 180,
    stylesTextInput: {
      // background: "transparent",
      borderBottom: "1px solid",
      background: "none",
      fontSize: "21px",
      // fontWeight: "bold",
      textAlign: "center",
      height: 26,
    },
    titleQuestion: [
      {
        num: "3",
        title: `
			Complete the text. Use the second conditional to write the verbs in the correct form.
							`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 150px;position: relative; font-size: 21px">
			<img src="img/FriendsPlus/Page75/E3/1.jpg" width="650px" height='600px'/>

			<div style="position: absolute; width: 570px ;top:82px;left:54px">
				<span >What changes <sup>1</sup># (you / make) if you 
        ruled the world? Here’s what some people said:</span>
			</div>

			<div style="position: absolute; width: 570px ;top:151px;left:54px">
				<span >‘If I <sup>2</sup># (be) a member of the 
        government, I <sup>3</sup># (support) the poor.’</span>
			</div>

			<div style="position: absolute; width: 570px ;top:241px;left:54px">
				<span >‘I <sup>4</sup># (stop) bullying in schools if I <sup>5</sup># (have) the power.’</span>
			</div>

			<div style="position: absolute; width: 570px ;top:312px;left:54px">
				<span >‘If I <sup>6</sup># (be) president of the world, I <sup>7</sup># (stop) teachers giving homework!’</span>
			</div>

			<div style="position: absolute; width: 570px ;top:400px;left:54px">
				<span >‘I <sup>8</sup># (allow) young people to vote if I <sup>9</sup># (can) make changes.’</span>
			</div>

      <div style="position: absolute; width: 570px ;top:472px;left:54px">
				<span >‘If it <sup>10</sup># (be) up to me, I <sup>11</sup># (introduce) taxes on junk food.’</span>
			</div>

		</div>
		
							`,
        answer: [
          "would you make",
          "were",
          "would support",
          "would stop",
          `had`,
          `were`,
          `would stop`,
          `would allow`,
          `could`,
          `were`,
          "would introduce",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E4",
    audio: "Audios/2.26.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page75/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 230,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		<headphone name="2.26"></headphone >
		Listen and check your answers. Then tell a partner which changes you would make.

						`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<h1 style="margin-top: 30px">Answers</h1>	
		<div style="display: flex;flex-wrap: wrap;gap: 10px;font-size: 25px;">
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">6</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">7</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">8</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">9</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">10</span>
				<span>#</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">11</span>
				<span>#</span>
			</div>
		
	  	</div>
	
						`,
        answer: [
          "would you make",
          "were",
          "would support",
          "would stop",
          "had",
          "were",
          "would stop",
          "would allow",
          "could",
          "were",
          "would introduce",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 230,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Complete the sentences. Use your own ideas.
						`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="margin-top: 20px">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>If I did nothing all day, …</span>
			</div>

			<div style="margin-top: 20px">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>If they closed all schools, …</span>
			</div>

			<div style="margin-top: 20px">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>I would help my neighbour if …</span>
			</div>

			<div style="margin-top: 20px">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>My parents would support me if …</span>
			</div>

			<div style="margin-top: 20px">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>The world would be a better place if …</span>
			</div>
	
						`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 230,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
		Work in pairs. Take turns to ask and answer questions using situations
		1–4 and your own ideas. Use the second conditional.

						`,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="position: relative;font-size:25px">
		<p style="font-weight: bold;font-size:25px;margin-left:80px;">What would you do or say if you …</p>
			<div style="margin-top: 20px;margin-left:80px;">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>found money in the street?</span>
			</div>

			<div style="margin-top: 20px;margin-left:80px;">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>saw someone in danger?</span>
			</div>

			<div style="margin-top: 20px;margin-left:80px;">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>met your idol?</span>
			</div>

			<div style="margin-top: 20px;margin-left:80px;">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>suddenly became rich?</span>
			</div>
			<div style="position: absolute;top:20px;left:10px;">
				<img src="img/FriendsPlus/Page75/20.jpg" />
			</div>
			
		</div>
						`,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P75-E7",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: `Talk with your partner what changes he / she 
        would do if he / she ruled the world. `,
    },
    questions: [
      {
        title: `
            <div style='margin-block: 30px;'>
              <finished></finished>
            </div>
            <div><textarea id="0" rows="7" ></textarea></div>
          `,
        answer: [""],
      },
    ],
  },
};

export default json;
