import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U2-P37-E1',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
		component: T6v2,
		hideBtnFooter: true,
		// inputSize: 35,
		titleQuestion: [
			{
				num: '1',
				title: `
					Look at the photo. Then check the meaning 
					of the words in <span style='color: rgb(0, 173, 238);'>blue</span> in the Football FAQs and 
					read the text. Do you pass or throw the ball 
					when you play football? Do you think it’s a 
					difficult sport? Would you like to do it? Why / 
					Why not?
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div><img src='img/FriendsPlus/Page37/E1/1.jpg' width='50%' /></div>
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U2-P37-E2',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
		component: T6v2,
		isHiddenCheck: true,
		inputSize: 35,
		maxLength: 1,
		titleQuestion: [
			{
				num: '2',
				title: `
					Which paragraph in the Football FAQs tells readers … ?
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='display: flex; gap: 20px;'>
						<div style='flex: 2; display: flex; gap: 20px; flex-direction: column; border-radius: 10px; border: 2px solid orange; padding: 10px;'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>the ideal location to do this sport<br />Paragraph: #</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>what they need to practise the sport<br />Paragraph: #</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>where the sport began<br />Paragraph: #</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>how to play football<br />Paragraph: #</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div>a team sport for a variety of fitness levels<br />Paragraph: #</div>
							</div>
						</div>

						<div style='flex: 3;'><img src='img/FriendsPlus/Page37/E1/1.jpg' width='70%' /></div>
					</div>
        `,
				answer: [`5`, `4`, `2`, `1`, `3`],
			},
		],
	},
	3: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U2-P37-E3',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page37/E3/Key/answerKey.png',
		component: T6v2,
		hintBox: [
			{
				src: [`an idea`, `an object`, `a person`, `a place`],
				borderColor: 'rgb(0, 169, 79)',
				width: 500,
			},
		],
		isHiddenCheck: true,
		inputSize: 100,
		// maxLength: 1,
		titleQuestion: [
			{
				num: '3',
				title: `
				Match the words in <span style='color: rgb(0, 173, 238);'>blue</span> in the Football FAQs
				with the words in the box.
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='margin-top: 20px; display: flex; gap: 20px; align-items: start;'>
						<div>
							<div><hintBox id='0'></hintBox></div>
							<div style='margin-top: 20px; flex: 1; display: flex; gap: 20px; flex-direction: column; border-radius: 10px; border: 2px solid orange; padding: 10px;'>
								<div>an idea - #</div>							
								<div>an object - #</div>							
								<div>a person - #</div>							
								<div>a place - #</div>							
							</div>
						</div>

						<div style='flex: 2;'><img src='img/FriendsPlus/Page37/E1/1.jpg' width='70%' /></div>
					</div>
        `,
				answer: [`which`, `which`, `who`, `where`],
			},
		],
	},
	4: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U2-P37-E4',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page37/E4/Key/answerKey.png',
		component: T6v2,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true,
		inputSize: 100,
		// maxLength: 1,
		titleQuestion: [
			{
				num: '4',
				title: `
					Complete sentences 1–5 with <i>who</i>, <i>which</i> or <i>where</i>.
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>That’s the shop # I bought my board.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>Mike’s the friend # lent me his life jacket.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>Equipment # is expensive isn’t always the best.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>I watched a video # showed the best techniques.</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>I don’t know anybody # has tried it.</div>
						</div>
					</div>
        `,
				answer: [`where`, `who`, `which`, `which`, `who`],
			},
		],
	},
	5: {
		unit: 'Unit 3',
		id: 'FP8-SB-demo-2023-U2-P37-E5',
		audio: '',
		video: '',
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page37/E5/Key/answerKey.png',
		component: T6v2,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true,
		inputSize: 100,
		// maxLength: 1,
		titleQuestion: [
			{
				num: '5',
				title: `
					Follow the steps in the Writing Guide.
				`,
				color: 'rgb(0, 169, 79)',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],
		questions: [
			{
				title: `
					<div><img src='img/FriendsPlus/Page37/E5/1.jpg' width='50%' /></div>
        `,
				answer: [],
			},
		],
	},
}

export default json
