import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P13-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page13/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Study the sentences from the interview on page 12. Then choose the correct answers to complete the rules.`,
        color: "rgb(27, 191, 215)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `do / don't_do`,
          `didn't_use_to / didn't_used_to`,
          `Did_..._use_to / Did_..._used_to`,
        ],
        answers: ["0-4", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
			  <div>
			    People used to watch the best pole-sitters.
			    Before, people didn’t use to have smartphones.
			    Did people really use to buy pet rocks?
			  </div>

			  <div style="margin-top: 20px; width: 600px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(92, 201, 222);
			    font-size: 20px;">
			    <div>
			      <div style="color: rgb(255, 255, 255); font-weight: bold; text-transform: uppercase; margin-right: 10px;
			        background-color: rgb(92, 201, 222); padding: 5px 15px; border-radius: 15px;">
			        <span>RULES</span>
			      </div>
			    </div>
			    <div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">1</div>
			        <div>
			          We use <i>used to</i> + infinitive when we talk about a regular action or
			          state in the past which we <input id='0' type='Circle' /> now.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">2</div>
			        <div>
			          The negative form is <input id='1' type='Circle' />.
			        </div>
			      </div>
			      <div style="margin-top: 5px; display: flex; gap: 20px;">
			        <div style="font-weight: bold; text-align: right;">3</div>
			        <div>
			          The question form is <input id='2' type='Circle' />.
			        </div>
			      </div>
			    </div>
			  </div>

        <div style='margin-top: 20px;'><img src='img/FriendsPlus/Page12/E3/1.jpg' width='100%' /></div>
			  `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P13-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page13/E2/Key/answerKey.png",
    component: T6v2,
    maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the online article with the words in the box.`,
        color: "rgb(27, 191, 215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='margin-top: 10px; display: flex; flex-direction: column; gap: 20px; align-items: center;'>
            <div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
              max-width: 420px; padding: 10px 30px; border-radius: 20px; border: 2px solid rgb(92, 201, 222);'>
              <span>didn't</span><span>to</span><span>use</span>
              <span>used</span><span style='text-decoration: line-through;'>used</span>
            </div>

            <div style='position: relative;'>
              <div><img src='img/FriendsPlus/Page13/E2/1.jpg' width='100%' /></div>
              <div style='position: absolute; top: 87px; left: 328px;'>#</div>
              <div style='position: absolute; top: 144px; left: 306px;'>#</div>
              <div style='position: absolute; top: 229px; left: 42px;'>#</div>
              <div style='position: absolute; top: 286px; left: 334px;'>#</div>
            </div>
          </div>
        `,
        answer: ["used", "didn't", "use", "to"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P13-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page13/E3/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 430,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `Look at the pictures of Michael in the past
        and Michael now. Use the ideas in 1–6 to
        write sentences about his life ten years ago.
        Use affirmative and negative forms of used to.`,
        color: "rgb(27, 191, 215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; gap: 30px; flex-wrap: wrap; align-items: end;'>
            <div><img src='img/FriendsPlus/Page13/E3/1.jpg' width='100%' /></div>
            <div style='display: flex; flex-direction: column; gap: 10px; 
              border: 1px solid rgb(27, 191, 215); border-radius: 20px; padding: 20px;'>
              <div style='display: flex; gap: 10px;'>
                <span>1</span>
                <div>
                  <div style='padding-left: 10px;'>work in an office</div>#
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <span>2</span>
                <div>
                  <div style='padding-left: 10px;'>have longer hair</div>#
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <span>3</span>
                <div>
                  <div style='padding-left: 10px;'>live in the USA</div>#
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <span>4</span>
                <div>
                  <div style='padding-left: 10px;'>wear smart clothes</div>#
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <span>5</span>
                <div>
                  <div style='padding-left: 10px;'>wear glasses</div>#
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <span>6</span>
                <div>
                  <div style='padding-left: 10px;'>like playing games</div>#
                </div>
              </div>
            </div>
          </div>
        `,
        answer: [
          "Michael didn't use to work in an office. | He didn't use to work in an office.",
          "He didn't use to have longer hair.",
          "He used to live in the USA.",
          "He didn't use to wear smart clothes.",
          "He used to wear glasses.",
          "He used to like playing games.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P13-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page3/E4/Key/answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
          Write true and false sentences about your past habits. 
          Use affirmative and negative forms of <i>used to</i> and the words in the box.`,
        color: "rgb(27, 191, 215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; gap: 20px; flex-wrap: wrap;'>
            <div style='display: flex; flex-direction: column; gap: 20xp; align-items: center;'>
              <div style='display: flex; flex-wrap: wrap; gap: 10px; align-items: center; justify-content: center;
                max-width: 370px; padding: 10px 20px; border-radius: 20px; border: 2px solid rgb(92, 201, 222);'>
                <span>be</span><span>be interested in</span><span>cry</span><span>go</span>
                <span>have</span><span>like</span><span>play</span><span>watch</span>
              </div>
              <div style='margin-top: 20px; display: flex; flex-direction: column; gap: 10px;'> 
                <div style='display: flex; gap: 10px;'>
                  <span>1</span>
                  <div style='font-style: italic; opacity: 0.8'>I used to watch cartoons with my sister when I was little.</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>2</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>3</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>4</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>5</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>6</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>7</span>
                  <div>#</div>
                </div>
                <div style='display: flex; gap: 10px;'>
                  <span>8</span>
                  <div>#</div>
                </div>
              </div>
            </div>

            <div>
              <div style='font-size: 25px; min-width: 200px; margin-left: 15px; margin-top: 10px; display: inline-block; color: rgb(48, 46, 47); font-weight: 600;'>
                Work in pairs. Read out your sentences. Guess whether your partner’s sentences are true or false.
              </div>
              <div><img src='img/FriendsPlus/Page13/E4/1.jpg' width='50%' /></div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P13-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    questionImage: [],
    hideBtnFooter: true,
    finished: {
      text: "Write questions to ask a partner about his / her past habits using Did you use to … ? and the topics in exercise 3 on page 11.",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
