import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P80-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page80/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
			Quickly read the blog and find the author’s opinion on the following names?
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div style="display: flex;flex-wrap: wrap;gap:40px;margin-top:30px; margin-left:100px">
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>Robert Downey Jr</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>Gone with the Wind
				</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>Ashish Sharma</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>Bãhubali</span>
			</div>
		</div>	

		<div style="margin-top: 40px">
			<img src="img/FriendsPlus/Page80/10.jpg" width="1200px" />
		</div>

		<h1 style="margin-top: 30px">Answers</h1>

		<div style="margin-top: 30px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span>#</span>
		</div>

		<div style="margin-top: 30px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>#</span>
		</div>

		<div style="margin-top: 30px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>#</span>
		</div>

		<div style="margin-top: 30px">
			<span style="font-weight: bold; margin-right: 10px">4</span>
			<span>#</span>
		</div>
									`,
        answer: [
          "The author thinks the amount Robert Downey Jr. was paid is huge.",
          "The author thinks Gone with the Wind sounds terrible.",
          "The author thinks Ashish Sharma's record is truly unbelievable.",
          "The author thinks that the poster of the film Bãhubali is an extraordinary size.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P80-E2",
    audio: "Audios/2.30.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page80/key/E2answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		<headphone name="2.30"></headphone >
		Read and listen to the blog again. Choose the correct answers.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 30,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
          //   fontSize: "32px",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          "a|&ensp;is&nbsp;usually&nbsp;only&nbsp;about&nbsp;awards&nbsp;that&nbsp;stars&nbsp;win.<br/> b|&ensp;is&nbsp;less&nbsp;interesting&nbsp;than&nbsp;some&nbsp;other&nbsp;film&nbsp;facts.<br/> c|&ensp;isn't&nbsp;interesting",
          "a|&ensp;is&nbsp;not&nbsp;a&nbsp;modern&nbsp;film.<br/> b|&ensp;holds&nbsp;many&nbsp;film&nbsp;records.<br/> c|&ensp;made&nbsp;eighty&nbsp;million&nbsp;dollars.",
          "a|&ensp;plans&nbsp;to&nbsp;watch&nbsp;the&nbsp;film&nbsp;that&nbsp;will&nbsp;be&nbsp;120&nbsp;hours&nbsp;long.<br/> b|&ensp;has&nbsp;watched&nbsp;forty-eight&nbsp;films&nbsp;in&nbsp;his&nbsp;lifetime.<br/> c|&ensp;has&nbsp;had&nbsp;a&nbsp;film&nbsp;record&nbsp;for&nbsp;several&nbsp;years.",
        ],

        answers: ["2-8", "0-4", "1-0"],
        initialValue: {},
      },
      Layout: `
			<img src="img/FriendsPlus/Page80/10.jpg" style='height:15cm' />

		
		<div style="display: flex; flex-wrap: wrap;gap:5px">

			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
			</div>

			<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:10px">
				The writer believes that gossip about film
				stars …

				<div >
					<input id='0' type='Circle' style="letter-spacing: 10px;"/>
				</div>
			</div>
			
		</div>

		<div style="display: flex; flex-wrap: wrap;gap:10px;margin-top:30px">

			<div>
				<span style="font-weight: bold; margin-right: 10px">2</span>
			</div>

			<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:10px">
				Gone with the Wind …
				
				<div >
					<input id='1' type='Circle' style="letter-spacing: 10px;"/>
				</div>
			</div>
			
		</div>

		<div style="display: flex; flex-wrap: wrap;gap:10px;margin-top:30px">

			<div>
				<span style="font-weight: bold; margin-right: 10px">3</span>
			</div>

			<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:10px">
				Ashish Sharma …
				
				<div >
					<input id='2' type='Circle' style="letter-spacing: 10px;"/>
				</div>
			</div>
			
		</div>

		

		
			
  
			
  
				  `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P80-E3",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Read the Study Strategy. Practise reading the
		blog out loud.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div style="margin-left: 50px">
			<img src="img/FriendsPlus/Page80/11.jpg" />
		</div>
									`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P80-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		<span style="color: rgb(220,44,55)">VOCABULARY PLUS</span> Use a dictionary to check the meaning of the words in  <span style="color: rgb(28, 190, 215)">blue</span> in the text.
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div style="margin-top:30px">
			<img src="img/FriendsPlus/Page80/10.jpg" width="1200px" />
		</div>
									`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P80-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Which record did you find the most interesting? Why?
									`,
        color: "rgb(220,44,55)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		
									`,
        answer: [],
      },
    ],
  },
};

export default json;
