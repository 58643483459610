import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P39-E3",
    audio: "Audios/1.31.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 950,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name='1.31'></headphone >
        Watch or listen to four people talking about the environment. Which person is not concerned
        about the future of our planet? Why? Do you agree?
        `,
        color: "rgb(27, 191, 215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 20px; background-color: rgb(125,167,216); display: flex; justify-content: center; align-items: center; border: 1px solid rgb(0,103,180); margin-bottom:40px ">
    
        <div style="position: relative; text-align:center; margin-right:40px; flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Yana </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Mitchell </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Paul </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Will </p>
          
        </div>
      </div>




        <div style="margin-top: 20px;">
          <h3>1.Which person is not concerned about the future of our  planet?</h3>
          <p>#</p>
        </div>

        <div style="margin-top: 20px;">
          <h3>2.Why?</h3>
          <p>#</p>
        </div>

        <div style="margin-top: 20px;">
          <h3>3.Do you agree?</h3>
          <p>#</p>
        </div>
        `,
        answer: [
          `Speaker 4 (Will) isn't worried about the environment.`,
          `Because the Earth's been around for a long time and the weather's  always changing.`,
          `That's just the way things are.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P39-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in groups. Read the quote
        and talk about whether you agree with it or
        not using the key phrases.`,
        color: "rgb(125,167,216)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <img src="img/FriendsPlus/Page39/9.jpg"/>
         <img src="img/FriendsPlus/Page39/10.jpg"/>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P39-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Discuss three things that your school can do to reduce waste. What three things can you do at home?",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
