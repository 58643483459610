import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Copy and complete the word-web with the words in the box.`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='margin-top: 10px; display: flex; flex-direction: column; gap: 10px; align-items: center;'>
            <div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
              max-width: 560px; padding: 10px 30px; border-radius: 20px; border: 2px solid rgb(245, 128, 37);'>
              <span>baggy</span><span>blouse</span><span style='text-decoration: line-through;'>colourful</span>
							<span>shorts</span><span>indigo</span><span>trainers</span>
              <span>hat</span><span>jacket</span><span>patterned</span><span>shirt</span>
              <span>knee-length</span><span>skirt</span><span>scarf</span>
							<span>leggings</span><span>trousers</span>
            </div>

            <div style='position: relative;'>
              <div><img src='img/FriendsPlus/Page14/E1/1.jpg' width='100%' /></div>
              <div style='position: absolute; top: 59px; left: 80px;'>#</div>
              <div style='position: absolute; top: 89px; left: 80px;'>#</div>
              <div style='position: absolute; top: 119px; left: 80px;'>#</div>
              <div style='position: absolute; top: 149px; left: 80px;'>#</div>
              <div style='position: absolute; top: 179px; left: 80px;'>#</div>

              <div style='position: absolute; top: 81px; left: 520px;'>#</div>
              <div style='position: absolute; top: 110px; left: 520px;'>#</div>
              <div style='position: absolute; top: 139px; left: 520px;'>#</div>
              <div style='position: absolute; top: 168px; left: 520px;'>#</div>
              <div style='position: absolute; top: 197px; left: 520px;'>#</div>

							<div style='position: absolute; top: 316px; left: 347px; font-size: 20px; opacity: 0.8;'>colourful</div>
							<div style='position: absolute; top: 340px; left: 306px;'><input id=10 width=150px /></div>
							<div style='position: absolute; top: 370px; left: 306px;'><input id=11 width=150px /></div>
							<div style='position: absolute; top: 400px; left: 306px;'><input id=12 width=150px /></div>
							<div style='position: absolute; top: 430px; left: 306px;'><input id=13 width=150px /></div>
            </div>
          </div>
        `,
        answer: [
          "blouse | hat | jacket | shirt | scarf",
          "blouse | hat | jacket | shirt | scarf",
          "blouse | hat | jacket | shirt | scarf",
          "blouse | hat | jacket | shirt | scarf",
          "blouse | hat | jacket | shirt | scarf",
          "shorts | trainers | skirt | leggings | trousers",
          "shorts | trainers | skirt | leggings | trousers",
          "shorts | trainers | skirt | leggings | trousers",
          "shorts | trainers | skirt | leggings | trousers",
          "shorts | trainers | skirt | leggings | trousers",
          "baggy | indigo | patterned | knee-length",
          "baggy | indigo | patterned | knee-length",
          "baggy | indigo | patterned | knee-length",
          "baggy | indigo | patterned | knee-length",
        ],
        // answer: [
        // 	'blouse',
        // 	'hat',
        // 	'jacket',
        // 	'shirt',
        // 	'scarf',
        // 	'shorts',
        // 	'trainers',
        // 	'skirt',
        // 	'leggings',
        // 	'trousers',
        // 	'baggy',
        // 	'indigo',
        // 	'patterned',
        // 	'knee-length',
        // ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E2",
    audio: "Audios/1.09.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E2/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.09'></headphone >
					Listen and check your answers to exercise 1. Think of more words to add to the web.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 10px; position: relative;'>
						<div><img src='img/FriendsPlus/Page14/E1/1.jpg' width='100%' /></div>
						<div style='position: absolute; top: 59px; left: 80px;'>#</div>
						<div style='position: absolute; top: 89px; left: 80px;'>#</div>
						<div style='position: absolute; top: 119px; left: 80px;'>#</div>
						<div style='position: absolute; top: 149px; left: 80px;'>#</div>
						<div style='position: absolute; top: 179px; left: 80px;'>#</div>

						<div style='position: absolute; top: 81px; left: 520px;'>#</div>
						<div style='position: absolute; top: 110px; left: 520px;'>#</div>
						<div style='position: absolute; top: 139px; left: 520px;'>#</div>
						<div style='position: absolute; top: 168px; left: 520px;'>#</div>
						<div style='position: absolute; top: 197px; left: 520px;'>#</div>

						<div style='position: absolute; top: 316px; left: 347px; font-size: 20px; opacity: 0.8;'>colourful</div>
						<div style='position: absolute; top: 340px; left: 306px;'><input id=10 width=150px /></div>
						<div style='position: absolute; top: 370px; left: 306px;'><input id=11 width=150px /></div>
						<div style='position: absolute; top: 400px; left: 306px;'><input id=12 width=150px /></div>
						<div style='position: absolute; top: 430px; left: 306px;'><input id=13 width=150px /></div>
					</div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    textareaStyle: { width: 350 },
    titleQuestion: [
      {
        num: "3",
        title: `
				Work in pairs. Use the words in exercise 1 to describe the clothes of people in photos A–C. 
				Where are they?
				`,
        color: "rgb(245, 128, 37)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='text-align: center'><img src='img/FriendsPlus/Page14/E3/1.jpg' width='80%' /></div>
					
					<div style='margin-top: 20px; display: flex; flex-wrap: wrap; gap: 20px;'>
							<div>
								<div style='font-weight: 600; font-style: italic; margin-left: 10px;'>Photo A:</div>
								<div><textarea id="0" rows="7" ></textarea></div>
							</div>
							<div>
								<div style='font-weight: 600; font-style: italic; margin-left: 10px;'>Photo B:</div>
								<div><textarea id="1" rows="7" ></textarea></div>
							</div>
							<div>
								<div style='font-weight: 600; font-style: italic; margin-left: 10px;'>Photo C:</div>
								<div><textarea id="2" rows="7" ></textarea></div>
							</div>
					</div>
        `,
        answer: [
          "They’re wearing long blouses over short trousers with red scarves",
          "The girls are wearing long indigo  blouses over knee-length shorts and long scarves around their legs; the boy is wearing a black shirt, a long jacket and baggy trousers.",
          "The girl is wearing a pink T-shirt with a dark-blue jacket and blue jeans.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E4",
    audio: "Audios/1.10.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E4/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name='1.10'></headphone >
					Listen to Sam talking to his friend Chloe about his holiday. Which photo is mentioned in the recording?
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><span style='font-weight: 600; font-style: italic;'>Answer:</span> #</div>
					<div style='margin-top: 20px;'><img src='img/FriendsPlus/Page14/E3/1.jpg' width='100%' /></div>
        `,
        answer: ["Photo B | B"],
        // answer: ['Photo B'],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E5",
    audio: "Audios/1.10.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E5/Key/answerKey.png",
    component: T6,
    // maxLength: 1,
    inputSize: 750,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					<headphone name='1.10'></headphone > 
					Read the Study Strategy. Listen again and answer the questions.
          `,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='margin-top: 20px;'><img src='img/FriendsPlus/Page14/E5/1.jpg' width='45%' /></div>
				<div style='margin-top: 20px; display: flex; flex-direction: column; gap: 10px;'> 
					<div style='display: flex; gap: 10px;'>
						<span>1</span>
						<div>
							<div style='padding-left: 10px;'>What country did Sam visit?</div>#
						</div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span>2</span>
						<div>
							<div style='padding-left: 10px;'>Who did Sam and his sister visit there?</div>#
						</div>
					</div>		
					<div style='display: flex; gap: 10px;'>
						<span>3</span>
						<div>
							<div style='padding-left: 10px;'>What was the boy wearing?</div>#
						</div>
					</div>		
					<div style='display: flex; gap: 10px;'>
						<span>4</span>
						<div>
							<div style='padding-left: 10px;'>What were the girls wearing?</div>#
						</div>
					</div>		
				</div>
        `,
        answer: [
          "He visited Viet Nam. / He visited Việt Nam.",
          "They visited their dad.",
          "The boy was wearing baggy trousers, a long jacket and a black shirt.",
          "The girls were wearing long indigo blouses over knee-length shorts.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P14-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E6/Key/answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    titleQuestion: [
      {
        num: "6",
        title: `
				Work in pairs. Choose a photo of a person in this unit and describe his / her clothes. 
				Can your partner guess who he / she is?
				`,
        color: "rgb(245, 128, 37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 20px; display: flex; gap: 20px; flex-wrap: wrap'>
						<div style='flex: 1'><img src='img/FriendsPlus/Page14/E6/1.jpg' width='100%' /></div>
						<div style='flex: 3'><img src='img/FriendsPlus/Page14/E3/1.jpg' width='100%' /></div>
					</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
