import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P81-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
			Complete the sentences with the correct form of be or not be. Check
			your answers in the film records text.
							`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style="margin-top: 20px">
					<span style="font-weight: bold; margin-right: 10px">1</span>
					<span>What about the film trivia we #
					told about?</span>
				</div>

				<div style="margin-top: 20px">
					<span style="font-weight: bold; margin-right: 10px">2</span>
					<span>It # designed by the
					film’s director S. S. Rajamouli.
					</span>
				</div>

				<div style="margin-top: 20px">
					<span style="font-weight: bold; margin-right: 10px">3</span>
					<span>It # made in the
					usual way.</span>
				</div>

				<div style="margin-top: 20px">
					<span style="font-weight: bold; margin-right: 10px">4</span>
					<span>Gossip about stars #
					usually heard.</span>
				</div>
	
				
		
							`,
        answer: ["aren't", "was", "wasn't", "is"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P81-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 300,
    isRequired: false,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		Study the sentences in exercise 1. Complete rules a–c with the words in the box. Then
		find more passive sentences in the blog on page 80.
								`,
        color: "rgb(28, 190, 215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

			<div style="border: 2px solid rgb(28, 190, 215); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;width:800px; margin-left:150px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">am (not)</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">are (not) / is (not)</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">by</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">was (not) / were (not)</p>
			</div>

			<div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px;margin-top:30px;width:1000px;margin-left:50px;">
				<div
					style="
					background-color: rgb(28, 190, 215);
					border-radius: 40px;
					margin: 10px 10px;
					
					"
				>
					<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
				</div>

				<div style="margin: 10px 10px;font-size:22px">
					We use the passive when we are more
					interested in the action, rather than the person
					doing the action.
				</div>

				<div style="margin: 10px 10px;font-size:22px">
					<span style="font-weight: bold;margin-right:10px">a </span>
					In the present, we form the passive with
					the subject + # + the past participle.
				</div>

				<div style="margin: 10px 10px;font-size:22px">
					<span style="font-weight: bold;margin-right:10px">b </span>
					In the past, we form the passive with the
					subject + # + the past participle.
				</div>

				<div style="margin: 10px 10px;font-size:22px">
					<span style="font-weight: bold;margin-right:10px">c </span>
					We use # to talk about the person / thing that does an action in the
					passive.
				</div>

			</div>
			
			
			
								`,
        answer: [
          "am (not), are (not) / is (not)",
          "was (not) / were (not)",
          "by",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P81-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "780px" },
    // maxLength: 1,
    inputSize: 300,
    isRequired: false,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Correct the mistakes in the sentences. Then decide if they are true or false. Discuss
		your answers with a partner.

								`,
        color: "rgb(28, 190, 215)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px;margin-top:30px">
			<img src="img/FriendsPlus/Page81/8.jpg"  />
		</div>
		<h1 style="margin-top: 30px">Answers</h1>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">1</span>
			<div style="margin-top:-5px;"><textarea id="0" rows="3"></textarea></div>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">2</span>
			<div style="margin-top:-5px;"><textarea id="1" rows="3"></textarea></div>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">3</span>
			<div style="margin-top:-5px;"><textarea id="2" rows="3"></textarea></div>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">4</span>
			<div style="margin-top:-5px;"><textarea id="3" rows="3"></textarea></div>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">5</span>
			<div style="margin-top:-5px;"><textarea id="4" rows="3"></textarea></div>
		</div>

		<div style="display: flex; flex-wrap: wrap; gap: 10px">
			<span style="font-weight: bold;">6</span>
			<div style="margin-top:-5px;"><textarea id="5" rows="3"></textarea></div>
		</div>
								`,
        answer: [
          "More films are made in India than in any other country each year. (true)",
          "In 1923, a sign was put up in the Hollywood hills which said 'Hollywoodland'. (true)",
          "The Academy Awards weren't called 'Oscars' until 1939. (true)",
          "Mùa len trâu was set in northern Việt Nam. (false) Mùa len trâu was set in southern Việt Nam.",
          "Mr. Nguyễn Võ Nghiêm Minh was given Best Director Award at Chicago Film Festival in 2006. (false) Mr. Nguyễn Võ Nghiêm Minh was given Best Director Award at Chicago Film Festival in 2004.",
          "Films aren't seen by their directors until the premiere. (false) Films aren't seen by the public until the premiere.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P81-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page81/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 300,
    isRequired: false,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		Complete the review with the correct passive form of the verbs in the box.
								`,
        color: "rgb(28, 190, 215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="border: 2px solid rgb(28, 190, 215); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;width:800px; ">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">direct</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">remind</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">release</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">award</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">play</p>
		</div>

		<div style="margin-left: 60px;margin-top:30px;position: relative;">
			<img src="img/FriendsPlus/Page81/9.jpg"  />

			<div style="position: absolute;top:431px;left:247px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:462px;left:71px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:585px;left:41px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:678px;left:268px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:892px;left:147px">
				<span>#</span>
			</div>


		</div>
		
								`,
        answer: [
          "was released",
          "is directed",
          "is played",
          "are also reminded",
          "was awarded",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P81-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 300,
    isRequired: false,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Use the information in the box to tell each other about the film you
		have seen recently. Use passive forms where possible. Which film would you enjoy most?

								`,
        color: "rgb(28, 190, 215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div style="margin-left: 60px;margin-top:30px;">
			<img src="img/FriendsPlus/Page81/10.jpg"  />
		</div>
		
		
								`,
        answer: [],
      },
    ],
  },
};

export default json;
