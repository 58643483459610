import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E1',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page41/key/E1answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
			Complete the sentences with the quantifiers in the box. There is one extra quantifier.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div class="container">
				<div style="border: 1px solid rgb(28,190,215); display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px; font-weight:bold">
          <div style="margin-right:40px"><span>a few</span></div>
          <div style="margin-right:40px"><span>a little</span> </div>
          <div style="margin-right:40px"><span>a lot of</span></div>
          <div><span style="margin-right:40px">enough</span></div>
          <div><span style="margin-right:40px">many (x2)</span></div>
          <div><span>much (x2)</span></div>
				</div>


        <div style="margin-top: 30px; font-size: 25px;">
          <p style="font-size: 25px;">1 That's too # hungry children</p>
          <p style="font-size: 25px;">2 There are # hungry people in the world, but only # people are  trying to do something about it.</p>
         
          <p style="font-size: 25px;">3 We'll need to provide # protein.</p>

          <p style="font-size: 25px;">4 Insects don't need as # space or water.</p>
          <p style="font-size: 25px;">5 How # people will eat insects in the future?</p>
          <p style="font-size: 25px;">6 How # food made with insects will you eat?</p>
         
          
          
          
          
        </div>
				
			</div>
			
				
				`,
        answer: [
          'many',
          'a lot (of)',
          'a few',
          'enough',
          'much',
          'many',
          `much (a little isn't  used)`,
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E2',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page41/key/E2answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '2',

        title: `
        Answer the questions in the rules with the words in exercise 1.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="border: 4px solid rgb(28,190,215);border-radius:30px">
          <div style="background-color: rgb(28,190,215); border-radius: 40px; margin: 10px 10px;">
            <h3 style="padding-left: 20px; color: white; font-size: 30px;">RULES</h3>
          </div>
      
          <div style="margin-top: 10px; margin: 10px 10px;">
            <p style="font-size: 25px; ">1 Which quantifiers can we use with countable nouns?</p>
            <p style="font-size: 25px;">Answer:#</p>
          </div>

          <div style="margin-top: 10px; margin: 10px 10px;">
            <p style="font-size: 25px; ">2 Which quantifiers can we use with uncountable nouns?</p>
            <p style="font-size: 25px;">Answer:#</p>
          </div>


          <div style="margin-top: 10px; margin: 10px 10px;">
            <p style="font-size: 25px; ">3 Which quantifiers can we use to say there are small quantities of something?</p>
            <p style="font-size: 25px;">Answer:#</p>
          </div>


          <div style="margin-top: 10px; margin: 10px 10px;">
            <p style="font-size: 25px; ">4 Which quantifiers can we use to say there are large quantities of something?</p>
            <p style="font-size: 25px;">Answer:#</p>
          </div>

          <div style="margin-top: 10px; margin: 10px 10px;">
            <p style="font-size: 25px; ">5 Which quantifier means 'sufficient'?</p>
            <p style="font-size: 25px;">Answer:#</p>
          </div>


        </div>
		
			
				
				`,
        answer: [
          'many, a few, a lot of, enough',
          'enough, much, a little, a lot of',
          'a few, a little',
          'a lot of, much, many',
          'enough',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E3',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page41/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '3',

        title: `
        Read the tips for reducing waste. Then choose the correct options.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          <img style='width:1100px; height:500px' src ='img/FriendsPlus/Page41/13.jpg'/>
          <h2 style='margin-top:30px'>ANSWERS</h2>
          
          <div style="display: flex; gap:5;">
            <p style = 'font-size:25px ; margin-right: 20px;'>1#</p>
            <p style = 'font-size:25px ; margin-right: 20px;'>2#</p>
            <p style = 'font-size:25px ; margin-right: 20px;'>3#</p>
            <p style = 'font-size:25px ; margin-right: 20px;'>4#</p>
            <p style = 'font-size:25px'>5#</p>
            
          </div>

          <div style="display: flex; gap:5;">
          <p style = 'font-size:25px ; margin-right: 20px;'>6#</p>
          <p style = 'font-size:25px'>7#</p>   
          </div>
        </div>


       
		
			
				
				`,
        answer: [
          'much',
          'A lot of',
          'not enough',
          'how many',
          'too many',
          'a few',
          'a little',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E4',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page41/key/E4answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '4',

        title: `
        Complete the dialogues.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
          <div  class="col-12"><h1>A</h1> </div>
          
          <div style="display: flex; margin-bottom:20px">
              <div class="col-2" >
                <h1>Myra</h1>
              </div>
              
              <div class="col-10" style="margin-left:-60px">
                <span>How </span>
                  <span><sup>1</sup>#</span>
                  <span>
                    <span style="color: rgb(3,174,239);">meat</span>  do you <span style="color: rgb(3,174,239);">eat each week?</span>
                  </span>
              </div>
          </div>


          <div style="display: flex; margin-bottom:20px">
            <div class="col-2" >
              <h1>Pete</h1>
            </div>
        
            <div class="col-10" style="margin-left:-60px">
              <span>I love <span style="color: rgb(3,174,239);">meat</span> , so I  <span style="color: rgb(3,174,239);">eat
              </span> 
              <span>
                a 
                <sup>2</sup>
                #
              </span>
              <span>of it.</span>
              <span>
                <span style="color: rgb(3,174,239);">Twice a day</span> in fact.
              </span>
            </div>
          </div>

          <div style="display: flex; margin-bottom:20px">
            <div class="col-2" >
              <h1>Myra</h1>
            </div>
          
            <div class="col-10" style="margin-left:-60px">
              <span>That's </span>
              <span>
                
                <sup>3</sup>
                #
              
              </span>
              <span>
              much
              <span style="color: rgb(3,174,239);">meat!</span>
              </span>
    
            </div>
          </div>
        </div>


        <div class="container">
          <div  class="col-2"><h1>B</h1> </div>
    
            <div style="display: flex;">
                <div class="col-2" >
                  <h1>Simon</h1>
                </div>
                
                <div class="col-10" style="margin-left:-60px">
                  <span>
                    Do you 
                    <span style="color: rgb(3,174,239);">eat </span>
                  </span>
                  <span>
                    
                    <sup>4</sup>
                    #
                  
                  </span>
                  <span style="color: rgb(3,174,239);">fruit?</span>

                </div>
            </div>


            <div style="display: flex;">
              <div class="col-2" >
                <h1>Maria</h1>
              </div>
            
              <div class="col-10" style="margin-left:-60px">
                <span>
                  I think so. I <span style="color: rgb(3,174,239);">eat</span>  a  
                </span>
                <span>
                  <sup>5</sup>
                  #
                </span>
                <span style="color: rgb(3,174,239);"> pieces of fruit every evening.</span>
                <span> But my mum says it's </span>
                <span>
                  <sup>6</sup>
                  #
                </span>
                <span>enough.</span>
      
              </div>
            </div>

        </div>

				`,
        answer: ['much', 'lot', 'too', 'enough', 'few', 'not'],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E5',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '5',
        title: `
        ! Use the dialogues in exercise 4, replace the words in <span style ="color:rgb(3,174,239)">blue</span> with the words in the box and a time phrase.
		`,
        color: 'rgb(3,174,239)',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
        <div style="width:850px;border: 4px solid rgb(3,174,239);line-width:2; display: flex; flex-wrap: wrap; padding: 10px 20px; border-radius: 40px;">
          <div style="font-weight:bold;margin:20px"><span>chocolate / eat</span></div>
          <div style="font-weight:bold;margin:20px"><span>milk / drink</span></div> 
          <div style="font-weight:bold;margin:20px"><span>rubbish / recycle</span></div>
          <div style="font-weight:bold;margin:20px"><span>water / waste</span> </div>
        </div>
        <div><img src="img/FriendsPlus/Page41/14.jpg" /></div>

      </div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P41-E5',
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Write some rules for your class about how everyone can help reduce waste. Use quantifiers wherever possible.",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
