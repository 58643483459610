import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import MatchDots from '../../components/ExcerciseTypes/TypeIn/MatchDots';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P61-E1',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page61/key/E1answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 110,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
			Study the sentences from the texts on page 60. Do they talk about ability in the past, present or future?
	
									`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
			<p style="font-size: 25px; margin-top: 30px; ">
			  <span style="font-weight: bold; margin-right: 10px;">1</span>
			  <span>After they graduate, many students <span style="font-weight: bold;">will
					be able to</span>  have very successful careers as
					singers or actors
			  </span>
			  <p><span style="font-size: 25px;">Answer:</span> #</p>
			</p>
	
			<p style="font-size: 25px; margin-top: 30px; ">
			  <span style="font-weight: bold; margin-right: 10px;">2</span>
			  <span>Young people <span style="font-weight: bold;">can</span> join the school from the
					age of fourteen.
			  </span>
			  <p><p><span style="font-size: 25px;">Answer:</span> #</p></p>
			</p>
	
			<p style="font-size: 25px; margin-top: 30px; ">
			  <span style="font-weight: bold; margin-right: 10px;">3</span>
			  <span>They <span style="font-weight: bold;">can't</span> forget to do their homework! 
			  </span>
			  <p><p><span style="font-size: 25px;">Answer:</span> #</p></p>
			</p>
	
			<p style="font-size: 25px; margin-top: 30px; ">
			  <span style="font-weight: bold; margin-right: 10px;">4</span>
			  <span>If you study hard and have a passion for
			  performing arts, you <span style="font-weight: bold;">won't be able to</span> resist
			  the BRIT school. </span>
			  <p><p><span style="font-size: 25px;">Answer:</span> #</p></p>
			</p>

			<p style="font-size: 25px; margin-top: 30px; ">
			  <span style="font-weight: bold; margin-right: 10px;">5</span>
			  <span>People believed that girls <span style="font-weight: bold;">couldn't</span> play
			  football as well as boys</span>
			  <p><p><span style="font-size: 25px;">Answer:</span> #</p></p>
			</p>
			
				
									`,
        answer: ['future', 'present', 'present', 'future', 'past'],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U5-P61-E2',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page61/key/E2answerKey.png',
    stylesTextInput: { borderBottom: 'dotted' },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: '2',
        title: 'Match sentences 1–5 in exercise 1 with rules a–c. ',

        color: 'rgb(28,190,215)',
      },
    ],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '90px',
              left: '520px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '213px',
              left: '520px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '325px',
              left: '520px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '430px',
              left: '520px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '557px',
              left: '520px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '100px',
              right: '489px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '177px',
              right: '489px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 6
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '253px',
              right: '489px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 7
        ],
        answers: ['0-7', '1-5', '2-5', '3-7', '4-6'],
        initialValue: [],
      },
      Layout: `
	  <div class="container">
			<div style="display: flex;justify-content: space-between;align-items: center; flex-wrap: wrap; gap: 10px;min-width:1200px">
				<div class="col-5" style="flex:1;font-size:25px;margin-left:-60px">
					<p style="font-size: 25px; margin-top: 30px; ">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<span>After they graduate, many students <span style="font-weight: bold;">will
							be able to</span>  have very successful careers as
							singers or actors
						</span>
						<div><input id="0" type="boxMatch" /></div>

					</p>
	  
					<p style="font-size: 25px; margin-top: 30px; ">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<span>Young people <span style="font-weight: bold;">can</span> join the school from the
							age of fourteen.
						</span>
						<div><input id="1" type="boxMatch" /></div>

					</p>
	  
					<p style="font-size: 25px; margin-top: 30px; ">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<span>They <span style="font-weight: bold;">can't</span> forget to do their homework! 
						</span>
						<div><input id="2" type="boxMatch" /></div>
					</p>
	  
					<p style="font-size: 25px; margin-top: 30px; ">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<span>If you study hard and have a passion for
						performing arts, you <span style="font-weight: bold;">won't be able to</span> resist
						the BRIT school. </span>
						<div><input id="3" type="boxMatch" /></div>
					</p>
  
					<p style="font-size: 25px; margin-top: 30px; ">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<span>People believed that girls <span style="font-weight: bold;">couldn't</span> play
						football as well as boys</span>
						<div><input id="4" type="boxMatch" /></div>
					</p>


				</div>

				<div class="col-5" style="flex:1;">

					<div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px">
							<div
								style="
								background-color: rgb(28, 190, 215);
								border-radius: 40px;
								margin: 10px 10px;
								"
							>
								<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
							</div>

							<div style="margin: 10px 10px;font-size:22px">
								<span style="font-weight: bold;">a </span>
								We use can and can’t to talk about ability in the present.
								<div><input id="5" type="boxMatch" /></div>
							</div>

							<div style="margin: 10px 10px;font-size:22px">
								<span style="font-weight: bold;">b </span>
								We use could and couldn’t to talk about ability in the past.
								<div><input id="6" type="boxMatch" /></div>
							</div>

							<div style="margin: 10px 10px;font-size:22px">
								<span style="font-weight: bold;">c </span>
								We use will / won’t be able to to talk about ability in the future.
								<div><input id="7" type="boxMatch" /></div>
							</div>

					</div>

				</div>
			</div>
		</div>	


      `,
    },
  },
  3: {
    unit: 'Unit 5',
    id: 'FP8-SB-demo-2023-U5-P61-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page61/key/E3answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
        Choose the correct options.
					`,
        color: 'rgb(28,190,215)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { padding: 5, border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          `Will_you_be_able_to / Can_you`, // 0
          `we_could / will_be_able_to`, // 1
          `we_won't_be_able_to / couldn't `, // 2
          `can / could`, // 3
          `will_students_be_able_to / could_students`, // 4
          `can't / couldn't`, // 5
        ],
        answers: ['0-4', '1-4', '2-4', '3-0', '5-0', '4-4'],
        initialValue: {},
      },
      Layout: `
      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;">1 </span>
        ‘ <input id='0' type='Circle' style="letter-spacing: 10px;"/> hear the bell
        in your classroom?’ 
		  </div>


		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">2 </span>
			In the future, <input id='1' type='Circle' style="letter-spacing: 10px;"/> do
      all our exams online  
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">3 </span>
			At my last school, 
      <input id='2' type='Circle' style="letter-spacing: 10px;"/> use tablets in class. 
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">4 </span>
			These days, many students <input id='3' type='Circle' style="letter-spacing: 10px;"/>  
      find out what their homework is from the
      school’s website
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">5 </span>
      ‘In the past, <input id='4' type='Circle' style="letter-spacing: 10px;"/>   use computers in class?’
      ‘No, they couldn’t.’
			
		</div>

		<div style='width: 1000px;margin: 10px 10px;'>
			<span style="font-weight: bold;">6 </span>
      Turn the music down. I <input id='5' type='Circle' style="letter-spacing: 10px;"/> study. 
			
		</div>

		

				`,
    },
  },
  4: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P61-E4',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page61/key/E4answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 220,
    stylesTextInput: {
      background: 'transparent',
      border: 'none',
      // borderBottom: '1px solid red',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '4',

        title: `
        Complete the report with the words and phrases in the box. You can use them more
        than once.
         
								`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
			<div class="container">

        <div style="border: 2px solid rgb(28,190,215); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">can</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">can’t</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">couldn’t</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">will be able to</p>
        </div>

				<div style="position: relative;margin-left:100px">
          <img src="img/FriendsPlus/Page61/10.jpg" width="800px"/>
          <p style="position: absolute; top: 225px; left:20px">#</p>
          <p style="position: absolute; top: 296px; left:20px">#</p>
          <p style="position: absolute; top: 403px; left:20px">#</p>
          <p style="position: absolute; bottom: 168px; left:16px">#</p>
          <p style="position: absolute; bottom: 69px; left:16px">#</p>
        </div>
		 	</div>
		
	
			
								`,
        answer: [
          'can',
          "can't",
          "couldn't",
          'will be able to',
          'will be able to',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P61-E5',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '5',
        title: `
        Work in pairs. Which of these school issues are the same now as in the
        past? Which will be different in the future? Use can, can’t, could, couldn’t or will / won’t
        be able to
        `,
        color: 'rgb(28,190,215)',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 40px;margin-top:30px">

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">1</span>
            <span style="font-size: 25px">choice of subjects</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">2</span>
            <span style="font-size: 25px">ways of learning</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">3</span>
            <span style="font-size: 25px">school uniforms</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">4</span>
            <span style="font-size: 25px">class size</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">5</span>
            <span style="font-size: 25px">rules</span>
          </div>

        </div>

        <div style="margin-left: 100px;margin-top: 50px"><img src="img/FriendsPlus/Page61/11.jpg"/></div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
