import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E1",
    audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page8/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 80,
    stylesTextInput: {
      fontSize: "18px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				<headphone name="1.04"></headphone > 
				Complete the table with the infinitive and past simple forms of the verbs in <span style='color: rgb(0, 173, 238);'>blue</span> in the quiz. Then listen and check.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page8/E1/1.jpg' width='55%' /></div>

						<div style='position: absolute; top: 10px; left: 500px; width: 500px; border: 1px solid rgb(245, 128, 37); border-radius: 10px; padding: 20px;'>
							<div style='font-size: 20px; display: flex; gap: 30px; justify-content: space-between'>
								<div style='font-weight: bold; flex: 1; background: rgb(245, 128, 37); border-radius: 20px; padding: 3px 15px;'>Infinitive</div>
								<div style='font-weight: bold; flex: 1; background: rgb(245, 128, 37); border-radius: 20px; padding: 3px 15px;'>Past simple</div>
							</div>
							<div style='margin-top: 10px; font-size: 18px; display: flex;'>
								<div style='width: 50%; border-right: 1px solid black;'>
									<div style='color: rgb(149,144,144); font-style: italic;'>see</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
                  <div>#</div>
								</div>
								<div style='margin-left: 20px;'>
									<div style='color: rgb(149,144,144); font-style: italic;'>saw</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
									<div>#</div>
                  <div>#</div>
								</div>
							</div>
						</div>
					</div>
        `,
        answer: [
          'eat',
          'go',
          'ride',
          'teach',
          'be',
          'hear',
          'make',
          'get',
          'have',
          'do',
          'speak',
          'leave',
          'give',
          'buy','ate',
          'went',
          'rode',
          'taught',
          'were',
          'heard',
          'made',
          'got',
          'had',
          'did',
          'spoke',
          'left',
          'gave',
          'bought',
        ],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E2",
    audio: "Audios/1.04.mp3",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 80,
    stylesTextInput: {
      fontSize: "18px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `				
				Do the quiz. Compare your scores with a partner. Who has got the best memory?
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div><img src='img/FriendsPlus/Page8/E1/1.jpg' width='55%' /></div>
        `,
        answer: [
          "eat",
          "go",
          "teach",
          "be",
          "hear",
          "make",
          "get",
          "have",
          "do",
          "speak",
          "leave",
          "give",
          "buy",
          "ate",
          "went",
          "taught",
          "were",
          "heard",
          "made",
          "got",
          "had",
          "did",
          "spoke",
          "left",
          "gave",
          "bought",
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E3",
    audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page8/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "20px",
      background: "transparent",
      textAlign: "center",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",
        title: `				
				<headphone name="1.05"></headphone > 
				Listen to five short dialogues about some other memories and complete the Key Phrases.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(253, 226, 200);">
					<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(249, 162, 96); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
						Key Phrases
					</div>
					
					<div style="margin-top: 10px; padding: 0 20px">
						<div style="color: rgb(245, 128, 37); font-weight: bold; font-size: 1.6rem">
							Talk about the past
						</div>

						<div style='font-size: 20px;'>
							<div>
								When did you last <sup>1</sup> <input id='0' width='200px' />?
							</div>
							<div>
								At what age did you first <sup>2</sup> <input id='1' width='170px' />?
							</div>
							<div>
								When was the last time that you <sup>3</sup> <input id='2' width='150px' />?
							</div>
							<div>
								What was the last <sup>4</sup> # that you <sup>5</sup> #?
							</div>
							<div>
								What did you <sup>6</sup> # last <sup>7</sup> #?
							</div>
						</div>
					</div>
				</div>
        `,
        answer: [
          "wear sports clothes",
          "swim in the sea",
          "lost something",
          "thing",
          "bought",
          "do",
          "weekend",
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E4",
    audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page8/E4/Key/answerKey.png",
    component: T6v2,
    textareaStyle: { width: 600 },
    inputSize: 500,
    stylesTextInput: {
      fontSize: "20px",
      background: "transparent",
      textAlign: "center",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "4",
        title: `				
				<headphone name="1.05"></headphone > 
				Listen again. Write down the Key Prases.
				`,
        color: "rgb(245, 128, 37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div><img src='img/FriendsPlus/Page8/E4/1.jpg' width='45%' /></div>

				<div style='margin-top: 10px; display: flex; flex-direction: column;'>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>1</span>
						<div style='margin-bottom: -30px;'><textarea id="0" rows="2"></textarea></div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>2</span>
						<div style='margin-bottom: -30px;'><textarea id="1" rows="2"></textarea></div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>3</span>
						<div style='margin-bottom: -30px;'><textarea id="2" rows="2"></textarea></div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>4</span>
						<div style='margin-bottom: -30px;'><textarea id="3" rows="1"></textarea></div>
					</div>
					<div style='display: flex; gap: 10px;'>
						<span style='font-weight: bold;'>5</span>
						<div style='margin-bottom: -30px;'><textarea id="4" rows="3"></textarea></div>
					</div>
				</div>
        `,
        answer: [
          `I bought some new sports clothes on Saturday and I wore them yesterday at the gym.`,
          `I first swam in the sea when I was six. I remember I wasn't scared, but I was nervous.`,
          `I lost my sunglasses in the park last month. I was really annoyed because they were expensive.`,
          `I bought an app for my smartphone last night.`,
          `I went to a concert with my friends to see my favourite singer. She was amazing, but it's no surprise. She started to sing professionally when she was only six.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E5",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: ["Did you watch a film last night?"],
        borderColor: "rgb(245, 128, 37)",
        arrow: true,
        position: 1,
        width: 400,
      },
      {
        src: ["Yes, I did."],
        borderColor: "rgb(245, 128, 37)",
        arrow: true,
        position: 2,
        width: 150,
      },
      {
        src: ["What did you watch?"],
        borderColor: "rgb(245, 128, 37)",
        arrow: true,
        position: 1,
        width: 280,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Work in pairs. Ask and answer questions using the verbs in exercise 1 and the question words in the box.
				`,
        color: "rgb(245, 128, 37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='display: flex; justify-content: center;'>
					<div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
						max-width: 500px; padding: 10px 20px; border-radius: 20px; border: 2px solid rgb(245, 128, 37);'>
						<span>How much</span><span>What</span><span>Where</span>
						<span>Who</span><span>Why</span>
					</div>
				</div>

				<div style='margin-top: 30px; font-style: italic;'>
					<div style='margin-top: 20px;'><hintbox id='0'></hintbox></div>
					<div style='margin-left: 350px; margin-top: 20px;'><hintbox id='1'></hintbox></div>
					<div style='margin-top: 5px;'><hintbox id='2'></hintbox></div>
				</div>
					
				`,
        answer: [],
      },
    ],
  },
};

export default json;
