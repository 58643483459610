import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P12-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Read the interview with an expert on crazes. What is the purpose of the article?`,
        color: "rgb(219, 46, 57)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `
          a|&emsp;to&nbsp;give&nbsp;information<br> 
          b|&emsp;to&nbsp;sell&nbsp;digital&nbsp;products<br> 
          c|&emsp;to&nbsp;help&nbsp;people&nbsp;start&nbsp;a&nbsp;new&nbsp;craze
          `,
        ],
        answers: ["0-22"],
        initialValue: [],
      },
      Layout: `
        <div style='margin-left: 74px; display: flex; flex-direction: column; gap: 10px;'>
          <div><input id='0' type='Circle' /></div>
          <div><img src='img/FriendsPlus/Page12/E1/1.jpg' width='75%' /></div>
        </div>
        `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P12-E2",
    audio: "Audios/1.08.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 520,
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='1.08'></headphone > 
        Read and listen to the interview again. Write questions for answers 1–5.`,
        color: "rgb(219, 46, 57)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display: flex; gap: 20px; flex-direction: column;'>
            <div style='display: flex; gap: 10px;'>
              <span>1</span>
              <div>
                <div>In 1924. (When ....................?)</div>
                <div>#</div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span>2</span>
              <div>
                <div>For thirteen hours. (For how long ....................?)</div>
                <div>#</div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span>3</span>
              <div>
                <div>On social media. (Where ....................?)</div>
                <div>#</div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span>4</span>
              <div>
                <div>He sold pet rocks. (What ....................?)</div>
                <div>#</div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span>4</span>
              <div>
                <div>Millions. (How many ....................?)</div>
                <div>#</div>
              </div>
            </div>
          </div>
        `,
        answer: [
          "When did the craze of pole-sitting start?",
          "For how long did Alvin Kelly sit on a pole?",
          "Where do crazes often start these days?",
          "What did Gary Dahl do / sell in the 1970s?",
          "How many pet rocks did he sell?",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P12-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page12/E3/Key/answerKey.png",
    component: UI,
    // maxLength: 1,
    inputSize: 520,
    titleQuestion: [
      {
        num: "3",
        title: `
          <span style="color: rgb(219, 46, 108);">VOCABULARY PLUS</span> 
          Use a dictionary to check the meaning of the words in <span style='color: rgb(0, 188, 247);'>blue</span> in the text.`,
        color: "rgb(219, 46, 57)",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page12/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P12-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page12/E4/Key/answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 520,
    titleQuestion: [
      {
        num: "4",
        title: `
          <span style="color: rgb(219, 46, 108);">VOCABULARY PLUS</span> 
          Work in groups. Say what you think of the crazes in the interview. Take a vote on which is the silliest, the strangest and the most fun.`,
        color: "rgb(219, 46, 57)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          
        `,
        answer: [],
      },
    ],
  },
};

export default json;
