import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	5: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P27-E5',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page27/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
					Choose the correct answers.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		question: {
			Write: {
				inputStyle: {
           fontWeight: 100,
        },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					fontWeight: 500,
					fontStyle: 'italic',
					color: '',
					// fontSize: 27,
					borderRadius: '50%',
					// marginInline: '-1px',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWordsStyle: {},
				listWords: [
					`a <br> b <br> c`,
					`a <br> b <br> c`,
					`a <br> b <br> c`,
					`a <br> b <br> c`,
					`a <br> b <br> c`,
					`a <br> b <br> c`,
				],
				answers: ['0-4', '1-0', '2-4', '3-0', '4-8', '5-4'],
				initialValue: [],
			},
			Layout: `
        <div style='width: 700px'>
        Japanese inventor Satoshi Tajiri <sup>1</sup>.......... 
        <i>Pokémon</i>, or pocket monsters, in 1995. 
        By 1999, children <sup>2</sup>.......... their parents for 
        <i>Pokémon</i> games all over the world, and the 
        <i>Pokémon</i> company <sup>3</sup>.......... thousands of games 
        every day! Many crazes stop being popular 
        quite quickly, but this <sup>4</sup>.......... with <i>Pokémon</i>. 
        When the company <sup>5</sup>.......... its tenth anniversary, 
        millions of people <sup>6</sup>.......... <i>Pokémon</i> computer 
        games, cards and toys. Today, it is one of the 
        most successful game companies in the world. 
        </div>

        <div style='margin-top: 20px; display: flex; gap: 100px'>
          <div style='display: flex; gap: 20px; flex-direction: column;'>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>1</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='0' type='Circle' /></div>
                <div>
                  <div>used to create</div>
                  <div>created</div>
                  <div>was creating</div>
                </div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>2</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='1' type='Circle' /></div>
                <div>
                  <div>were asking</div>
                  <div>asked</div>
                  <div>asking</div>
                </div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>3</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='2' type='Circle' /></div>
                <div>
                  <div>sold</div>
                  <div>was selling</div>
                  <div>used to sell</div>
                </div>
              </div>
            </div>
          </div>

          <div style='display: flex; gap: 20px; flex-direction: column;'>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>4</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='3' type='Circle' /></div>
                <div>
                  <div>didn’t happen</div>
                  <div>wasn’t happening</div>
                  <div>doesn’t happen</div>
                </div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>5</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='4' type='Circle' /></div>
                <div>
                  <div>was celebrating</div>
                  <div>used to celebrate</div>
                  <div>celebrated</div>
                </div>
              </div>
            </div>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>6</span>
              <div style='display: flex; gap: 15px;'>
                <div><input id='5' type='Circle' /></div>
                <div>
                  <div>still buying</div>
                  <div>were still buying</div>
                  <div>still bought</div>
                </div>
              </div>
            </div>
          </div>
        </div>
			  `,
		},
	},
	6: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P27-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page27/E6/Key/answerKey.png',
		component: T6v2,
		inputSize: 330,
		stylesTextInput: {
			textAlign: 'center',
			borderStyle: 'dotted',
			fontSize: '24px',
		},
    hintBox:[{
      src:["it really suits you","It isn't really my style","it looks really good","There are some nice clothes"],
      width:1050,
      borderColor:"black"
    }],
		titleQuestion: [
			{
				num: '6',
				title: `
          Complete the conversation with the phrases. 
          Then practise with your partner.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
         <hintbox id=0></hintbox>

          <div style='margin-top: 20px; display: flex; flex-direction: column; gap: 10px;'>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Molly</div>
              <div>
                Hey, Hannah. What are you up to?
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Hannah</div>
              <div>
                Oh, hi, Molly. I'm looking for some jeans.
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Molly</div>
              <div>
              Did you find anything?
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Hannah</div>
              <div>
                Not really <sup>1</sup> # in here, but they're expensive.
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Molly</div>
              <div>
                Mmm, you're right. Look - that's a really cool jacket.
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Hannah</div>
              <div>
                Yeah, <sup>2</sup> #. Why don't you try it on?
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Molly</div>
              <div>
                <sup>3</sup> #.
              </div>	
            </div>
            <div style='display: flex; gap: 20px;'>
              <div style='font-weight: bold; width: 80px;'>Hannah</div>
              <div>
                I think <sup>4</sup> #. Go on, try it on!
              </div>	
            </div>
          </div>
        `,
				answer: [
					`There are some nice clothes`,
					`it looks really good`,
					`It isn't really my style`,
					`it really suits you`,
				],
			},
		],
	},
	7: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P27-E7',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page27/E7/Key/answerKey.png',
    // hideBtnFooter: true,
		titleQuestion: [
			{
				num: '7',
				title: `
          Choose the correct words.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		question: {
			Write: {
				inputStyle: {
          
        },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
          marginLeft: '10px',
					padding: '-30px',
					fontWeight: 100,
					fontStyle: 'italic',
					color: '',
					// fontSize: 27,
					borderRadius: '50%',
					// marginInline: '-1px',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWordsStyle: {
        
        },
				listWords: [
					`a_In_these&nbsp;&nbsp;&nbsp;&nbsp; b_In_those&nbsp;&nbsp;&nbsp;&nbsp; c_On_these&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_Such_as&nbsp;&nbsp;&nbsp;&nbsp; b_Like&nbsp;&nbsp;&nbsp;&nbsp; c_For_example&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_The_sixties_was_the_decade&nbsp;&nbsp;&nbsp;&nbsp; b_In_the_sixties&nbsp;&nbsp;&nbsp;&nbsp; c_These_days&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_like&nbsp;&nbsp;&nbsp;&nbsp; b_of&nbsp;&nbsp;&nbsp;&nbsp; c_as&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_At&nbsp;&nbsp;&nbsp;&nbsp; b_When&nbsp;&nbsp;&nbsp;&nbsp; c_For&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_On&nbsp;&nbsp;&nbsp;&nbsp; b_In&nbsp;&nbsp;&nbsp;&nbsp; c_At&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_decade&nbsp;&nbsp;&nbsp;&nbsp; b_times&nbsp;&nbsp;&nbsp;&nbsp; c_days&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_example&nbsp;&nbsp;&nbsp;&nbsp; b_such&nbsp;&nbsp;&nbsp;&nbsp; c_for_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
					`a_such&nbsp;&nbsp;&nbsp;&nbsp; b_like&nbsp;&nbsp;&nbsp;&nbsp; c_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
				],
				answers: [
					'0-2',
					'1-4',
					'2-0',
					'3-4',
					'4-0',
					'5-2',
					'6-0',
					'7-4',
					'8-2',
				],
				initialValue: [],
			},
			Layout: `
        <div style='position: relative; padding: 15px; border: 1px solid rgb(143, 144, 148); border-radius: 10px; box-shadow: 7px 7px 10px rgb(143, 144, 148);'>
          <div style='position: absolute; top: 90px; left: 421px;'><img src='img/FriendsPlus/Page27/E7/1.jpg' width='70%' /></div>
          <div style='font-size: 28px;'>Fact file: <strong>Flared trousers</strong></div>
          <div style='margin-top: 7px; display: flex; flex-direction: column; gap: 5px;'>
            <div style='width: 400px;'>
              <strong>The beginnings</strong>
              <div>
              American sailors were the first to 
              wear flared trousers in the 19th 
              century. <sup>1</sup>.......... days, trousers 
              with a wide leg below the knee were 
              practical for many reasons. 
              <sup>2</sup>.........., they were easy to roll 
              up when cleaning the ship or to take 
              off when they got wet.
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>Craze of the 1960s and 1970s</strong>
              <div>
                <sup>3</sup>.......... when flared trousers 
                became popular. Many singers, 
                such <sup>4</sup>.......... Sonny and Cher, 
                wore them. <sup>5</sup>.......... that time,  
                young people made their own flared trousers because 
                shops weren’t selling them. <sup>6</sup>.......... the 
                seventies, you could buy them in almost every shop. 
                Hardly anyone chose a different style of trousers 
                during this <sup>7</sup>.......... .
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>A comeback</strong>
              <div>
              There were a few times when flared trousers came 
              back, <sup>8</sup>.........., throughout the 1990s and in 
              2015. Although fashion designers, <sup>9</sup>.......... 
              Balmain and Gucci, presented them during fashion 
              shows, they didn’t become as popular as in the 1970s.
              </div>
            </div>
          </div>
        </div>

        <div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>1</span>
            <div><input id='0' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>2</span>
            <div><input id='1' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>3</span>
            <div><input id='2' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>4</span>
            <div><input id='3' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>5</span>
            <div><input id='4' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>6</span>
            <div><input id='5' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>7</span>
            <div><input id='6' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>8</span>
            <div><input id='7' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>9</span>
            <div><input id='8' type='Circle' /></div>
          </div>
        </div>
			  `,
		},
	},
}

export default json
