import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P79-E4",
    audio: "Audios/2.29.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page79/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 400,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name="2.29"></headphone >
        Watch or listen to four people: Will, Emma, Elizabeth and Alicia talking
        about their favourite books. What genre(s) is each book?
								`,
        color: "rgb(71,140,202)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <h1 style="margin-top: 30px">Answers</h1>

        <div style="margin-top: 20px">
          <span style="font-weight: bold; margin-right: 10px">Will:</span>
          <span>#</span>
        </div>

        <div style="margin-top: 20px">
          <span style="font-weight: bold; margin-right: 10px">Emma:</span>
          <span>#</span>
        </div>

        <div style="margin-top: 20px">
          <span style="font-weight: bold; margin-right: 10px">Elizabeth:</span>
          <span>#</span>
        </div>

        <div style="margin-top: 20px">
          <span style="font-weight: bold; margin-right: 10px">Alicia:</span>
          <span>#</span>
        </div>
								`,
        answer: [
          "crime",
          "science fiction",
          "science fiction graphic novel / romance",
          "drama",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P79-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 400,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: "5",
        title: `
        Work in groups. Take turns to describe a famous book or film using
        the key phrases. Guess other people’s books and films.
								`,
        color: "rgb(71,140,202)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-left: 200px;margin-top:30px">
          <img src="img/FriendsPlus/Page79/8.jpg"/>
          <img src="img/FriendsPlus/Page79/9.jpg"/>
        </div>
        
								`,
        answer: [],
      },
    ],
  },
  6: {

    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P79-E6 ",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: `Write a description of your favourite film. 
        Read your description to your partner for 
        him / her to guess.`,
    },
    questions: [
      {
        title: `
            <div style='margin-block: 30px;'>
              <finished></finished>
            </div>
            <div><textarea id="0" rows="7" ></textarea></div>
          `,
        answer: [""],
      },
    ],
  }
};

export default json;
