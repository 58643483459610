import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  5: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P67-E5',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page67/key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: 'Tick (✓) the correct sentence.',
        color: 'rgb(121,106,175)',
      },
    ],
    questionImage: [
      // [{ url: "img/FriendsPlus/Page21/E1/26.jpg" }],
      [
        { url: 'img/FriendsPlus/Page67/E5/1.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/2.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E5/3.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/4.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page67/E5/7.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/8.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E5/11.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/12.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page67/E5/15.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/16.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/17.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page67/E5/19.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/20.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/21.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/22.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E5/23.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/24.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/25.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/26.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page67/E5/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E5/28.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/29.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E5/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E5/31.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/32.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E5/33.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/34.jpg' }],
      [
        { url: 'img/FriendsPlus/Page67/E5/35.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/36.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page67/E5/37.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page67/E5/5.jpg' }],
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U5-P67-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page67/key/E6answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '6',
        title: `
    Choose the correct words. Then practise with
    your partner.

					`,
        color: 'rgb(121,106,175)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          `have / get / got`, // 0
          `time / day / hour`, // 1
          `plan / hope / want`, // 2
          `remember / remind / forget`, // 3
          `leaves / leaving / left`, // 4
          `my / I / me`, // 5
        ],
        answers: ['0-8', '1-0', '2-4', '3-8', '4-0', '5-8'],
        initialValue: {},
      },
      Layout: `
      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Mum </span>
        So, Leah, you’ve <sup>1</sup>  
        <input id='0' type='Circle' style="letter-spacing: 10px;"/> a
        busy day ahead. 
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Leah </span>
        I know, it’s crazy. And I’ve got a basketball match tonight, too
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Mum </span>
        What <sup>2</sup>     
        <input id='1' type='Circle' style="letter-spacing: 10px;"/> does it start?
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Leah </span>
        It starts at six. Are you coming to watch?
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Mum </span>
        Yes, I <sup>3</sup>  

        <input id='2' type='Circle' style="letter-spacing: 10px;"/>
        so. Don’t <sup>4</sup>  

        <input id='3' type='Circle' style="letter-spacing: 10px;"/> that
        we’re going to London tomorrow.
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Leah </span>
        What? Why?
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Mum </span>
        Remember – it’s your cousin’s wedding. The train <sup>5</sup>
        <input id='4' type='Circle' style="letter-spacing: 10px;"/> at seven, so we can’t be late.
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Leah </span>
        Cool. Can you remind <sup>6</sup>  
        <input id='5' type='Circle' style="letter-spacing: 10px;"/> to get him a present later?
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Mum </span>
        Right – I’m reminding you now to get your cousin a present. OK?
		  </div>

      <div style='width: 1000px;margin: 10px 10px;'>
        <span style="font-weight: bold;margin-right:30px">Leah </span>
       OK. Thanks, Mum.
		  </div>


		

		

				`,
    },
  },
  7: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P67-E7',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page67/key/E7answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    hintBox: [{
      src: ["more", "for", "than", "including", "similar", "natural", "take", "advanced", "create", "troubles", "quickly"],

    }],
    textAlign: 'center',

    titleQuestion: [
      {
        num: '7',

        title: `
					
        Read the blog and complete it with the
        correct words. There are two extra words.
											`,
        color: 'rgb(121,106,175)',
      },
    ],
    stylesTextInput: {

      fontSize: "21px",
    },

    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
			
					<hintbox id='0'></hintbox>
          
          <div style="position: relative;">
            <img style='height:19cm;width:24cm' src="img/FriendsPlus/Page67/E7/1.jpg"/>
            <p style="font-size: 21px;position: absolute;top:105px; left:105px;width:20cm">
                  If we were to 
                  set off for a new 
                  home besides 
                  Earth, it would be very likely that the planet 
                  must have had a very <sup>1</sup># 
                  atmosphere compared to our homeland. 
                  It would not be hard for us to <sup>2</sup>#
                  adapt to the environment there. With the 
                  advanced technology nowadays, it is even 
                  possible that we can create an artificial 
                  sun <sup>3</sup># lighting, as well as day 
                  and night system. Depending on the 
                  ecosystem there, we may choose the best 
                  ways to develop <sup>4</sup># in terms of 
                  agriculture or industry. 
                  As we are settling down on a new place, 
                  our life will be surrounded with lots of 
                  work, <sup>5</sup># growing and finding 
                  edible plants and vegetables, exploiting 
                  the planet's essential <sup>6</sup>#
                  resources for recreating a new society. It will 
                  <sup>7</sup># a lot of time to rebuild a new 
                  society, but with the great intelligence of 
                  human beings nowadays, that process will 
                  be much shorter <sup>8</sup># before.
                  In addition, people may also discover 
                  new materials that can be used to 
                  <sup>9</sup># extremely advanced 
                  inventions and techniques that can help 
                  our lives on a new planet become much 
                  more comfortable.
            </p>
          </div>
					
				</div>
						
											`,
        answer: [
          'similar',
          'quickly',
          'for',
          'more',
          'including',
          'natural',
          'take',
          'than',
          'create',
        ],
      },
    ],
  },
};

export default json;
