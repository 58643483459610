import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P47-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Rewrite the sentences using one of the words in brackets.
							`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		
        <div style="line-height:60px"><b>1</b>&ensp; We have bought the tickets to Australia. (yet / just)<br>#</div>
			
        <div style="line-height:60px"><b>2</b>&ensp; I have invited Kim to my party. (still / already)<br>#</div>
       
        <div style="line-height:60px"><b>3</b>&ensp; Max hasn't finished his homework. (still / already)<br>#</div>
       <div style="line-height:60px"><b>4</b>&ensp; We have talked about this problem. (already / yet)<br>#</div>
        
       <div style="line-height:60px"><b>5</b>&ensp; I haven't found my phone. (already / yet)<br>#</div>
			
      <div style="line-height:60px"> <b>6</b>&ensp; Have you repaired your bike? (still / yet)<br>#</div>
			

			
		
		</div>
							`,
        answer: [
          "We have just bought the tickets to Australia.",
          "I have already invited Kim to my party.",
          "Max still hasn't finished his homework.",
          "We have already talked about this problem.",
          "I haven't found my phone yet.",
          "Have you repaired your bike yet?",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P47-E6",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 450,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",

        title: `
        Complete the conversation with the key phrases. Then practise with your partner.
							`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		<div class="container">
		<div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">Was it good? </p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">Well, good luck with that. </p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">What have you been up to? </p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">It was good to see you again. </p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">Have you been doing anything special? </p>
			
			
		</div>

    <div style="display: flex; margin-top:40px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">Hi, Emily. I haven't seen you for ages.<br/> <sup>1</sup> #</p>
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Emily</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">I've just got back from one of those
          adventure centres with my school.</p>
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;"><sup>2</sup> #</p>
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Emily</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">It was amazing. I've done a lot over the last few days - kayaking,
          climbing, even abseiling. What about you? <br/> <sup>3</sup> #</p>
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">Not really. I've been at home, studying. We've got exams all week. </p>
          
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Emily</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">Oh. <sup>4</sup> #</p>
				</div>
		</div>

    <div style="display: flex; margin-top:10px">
				<div class="col-2" style=" margin-right:-60px">
					<p style="font-size: 25px; font-weight: bold;">Joe</p>
				</div>
				<div class="col-8">
					<p style="font-size: 25px;">Thanks, Emily <sup>5</sup> #</p>
				</div>
		</div>
    

    

		
							`,
        answer: [
          "What have you been up to?",
          "Was it good?",
          "Have you been doing anything special?",
          "Well, good luck with that.",
          "It was good to see you again.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P47-E7",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E7answerKey.png",
    component: T6v2,
    checkUppercase: true,
    maxLength: 16,
    inputSize: 180,
    stylesTextInput: {
      background: "transparent",
      borderBottom: "1px solid",
      textAlign: "center",
      fontSize: "20px",
      // fontWeight: "bold",
    },
    hintBox: [
      {
        src: [
          "become popular",
          "check out",
          "everyone who",
          "involves playing",
          "it’s best to",
          "learning to",
          "to learn",
          "where is",
          "where you",
          "which protects",
        ],
        width: 1000,
        border: "rgb(121,106,175)",
      },
    ],
    titleQuestion: [
      {
        num: "7",

        title: `
        Complete the FAQ page with the phrases. There are two extra phrases. 
							`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		<div class="container">
     <hintbox id=0></hintbox>

      <div style="margin-left:100px">
        <img style='width:20cm' src='img/FriendsPlus/Page47/6.jpg'>
        <div style='background: rgb(220 217 236);padding:0px 10px 20px 10px;width:20cm;font-size:20px;'>
        It’s a type of water sport which has started to 
        <sup>1</sup># . In underwater hockey, like in standard 
        hockey, two teams try to move a puck into a goal. 
        However, underwater hockey <sup>2</sup># at the 
        bottom of the swimming pool. <br>
        <b>Q</b> What equipment do you need?<br>
        A mask to help you see underwater and a snorkel to 
        help you breathe. You also need fins, gloves and a 
        special hat <sup>3</sup># your head and ears. Each 
        player has also got a short stick to move the puck. <br>
        <b>Q</b> Who can play?<br>
        Underwater hockey is for <sup>4</sup># can swim. 
        It’s easy <sup>5</sup># , but to be good, you must be 
        strong, fast and able to hold your breath for a long 
        time! When you’re learning, <sup>6</sup># treat it as 
        a fun activity.<br>
        <b>Q</b> Where can I find out more?<br>
        <sup>7</sup># our ‘Find a team’ page to see if there 
        are any local clubs <sup>8</sup># can join in.</div>
      </div>
		</div>
    
							`,
        answer: [
          "become popular",
          "involves playing",
          "which protects",
          "everyone who",
          "to learn",
          "it's best to",
          "Check out",
          "where you",
        ],
      },
    ],
  },
};

export default json;
