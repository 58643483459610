import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E1',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E1/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `Complete the sentences from the listening on page 14 with the words in the box. Then choose the correct option in the rule.`,
				color: '#1cbed5',
			},
		],
		question: {
			Write: {
				inputStyle: {
					width: 120,
					textAlign: 'center',
				},
				answers: [`doing`, `were`, `was`, `wearing`],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					// fontWeight: 600,
					color: '',
					// fontSize: 27,
					borderRadius: '50%',
					// marginInline: '-1px',
				},
				selectWordStyle: { border: '3px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWordsStyle: {},
				listWords: [`short_actions / long_actions`],
				answers: ['0-4'],
				initialValue: [],
			},
			Layout: `
      <div style='display: flex; justify-content:center; margin: 10px'>
         <span style='border:3px solid rgb(28, 190, 213);border-radius: 20px; padding: 0 15px 0 15px;'>
				 was &emsp; wearing &emsp; were &emsp; doing</span>
      </div>

					<div style='display: flex;'>
						<div><b>1 &emsp;</b></div>
						<div>What were you <input  id='0'/> in Sa Pa? </div>
					</div>
					<div style='display: flex;'>
						<div><b>2 &emsp;</b></div>
						<div>We <input  id='1'/> visiting my dad for a couple of weeks.</div>
					</div>
					<div style='display: flex;'>
						<div><b>3 &emsp;</b></div>
						<div>I <input  id='2'/> walking down the street with my sister.</div>
					</div>
					<div style='display: flex;'>
						<div><b>4 &emsp;</b></div>
						<div>The girls were <input  id='3'/> long indigo blouses over knee-length shorts.</div>
					</div>

        <div style='width: 100vh; display: flex; justify-content:center'>
					<div style='width: 600px;height:170px; 
							background-repeat: no-repeat;
							background-size: 600px 170px;
							background-image: url("img/FriendsPlus/Page15/E1/1.jpg");
							'>
							<div style='padding: 73px 15px 0 25px'>
							We use the past continuous to describe <b><input id='0' type='Circle' /></b> in the past.
							</div>
					</div>
				</div>
					
        `,
		},
	},
	2: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E2',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E2/Key/answerKey.png',
		component: T6,
		inputSize: 190,
		titleQuestion: [
			{
				num: '2',
				title: `Look at the picture. What were the people doing? Complete the sentences using the past continuous form of the verbs in the box.`,
				color: '#1cbed5',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div style='width: 100vh; display: flex; justify-content:center'><img src='img/FriendsPlus/Page15/E2/1.jpg' width=60% /></div>
            <div style='display: flex; justify-content:center; margin: 10px'>
               <span style='border:3px solid rgb(28, 190, 213);border-radius: 20px; padding: 0 15px 0 15px;'>
							 carry &emsp; listen &emsp; not look &emsp; shop &emsp; steal
							 </span>
            </div>

              <div style='display: flex;'>
                <div><b>1. &emsp;</b></div>
                <div>Two friends # at a market.</div>
              </div>
              <div style='display: flex;'>
                <div><b>2. &emsp;</b></div>
                <div>Kat # three bags.</div>
              </div>
              <div style='display: flex;'>
                <div><b>3. &emsp;</b></div>
                <div>She # at her handbag.</div>
              </div>
              <div style='display: flex;'>
                <div><b>4. &emsp;</b></div>
                <div>A man # her purse.</div>
              </div>
              <div style='display: flex;'>
                <div><b>5. &emsp;</b></div>
                <div>Her friend didn't notice because she # to music.</div>
              </div>
        `,
				answer: [
					`were shopping`,
					`was carrying`,
					`wasn't looking`,
					`was stealing`,
					`was listening`,
				],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E3',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E3/Key/answerKey.png',
		component: T6,
		inputSize: 110,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '3',
				title: `Complete the questions using the past continuous form of the verbs in the box. Then ask and answer the questions with a partner.`,
				color: '#1cbed5',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div style='width: 100vh; display: flex; justify-content:center'><img src='img/FriendsPlus/Page15/E2/1.jpg' width=60% /></div>
            <div style='display: flex; justify-content:center; margin: 10px'>
               <span style='border:3px solid rgb(28, 190, 213);border-radius: 20px; padding: 0 15px 0 15px;'>
							 do &emsp; shine &emsp; sit &emsp; talk
							 </span>
            </div>

              <div style='display: flex;'>
                <div><b>1. &emsp;</b></div>
                <div>What # your mother # at six o'clock yesterday evening?</div>
              </div>
              <div style='display: flex;'>
                <div><b>2. &emsp;</b></div>
                <div>Who # you # to this time last week?</div>
              </div>
              <div style='display: flex;'>
                <div><b>3. &emsp;</b></div>
                <div># the sun # last Sunday?</div>
              </div>
              <div style='display: flex;'>
                <div><b>4. &emsp;</b></div>
                <div>Where # you # this time yesterday?</div>
              </div>
        `,
				answer: [
					`was`,
					`doing`,
					`were`,
					`talking`,
					`Was`,
					`shining`,
					`were`,
					`sitting`,
				],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E4',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E4/Key/answerKey.png',
		component: T6,
		// inputSize: 110,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '4',
				title: `Study sentences 1–4. Then match them with rules a–d. Which tenses do we use in each sentence? How do you say when and while in Vietnamese?`,
				color: '#1cbed5',
				// prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
              <div style='display: flex;'>
                <div><b>1. &emsp;</b></div>
                <div>We were waiting for a bus when I took this photo.</div>
              </div>
              <div style='display: flex;'>
                <div><b>2. &emsp;</b></div>
                <div>The girl was wearing a colourful dress.</div>
              </div>
              <div style='display: flex;'>
                <div><b>3. &emsp;</b></div>
                <div>They saw my Manchester United shirt.</div>
              </div>
              <div style='display: flex;'>
                <div><b>4. &emsp;</b></div>
                <div>While I was talking to him, my sister was learning Japanese words.</div>
              </div>
            <div style='width: 100vh; display: flex; justify-content:center'><img src='img/FriendsPlus/Page15/E4/1.jpg' width=55% /></div>

              <div style='display: flex;justify-content: space-around; width: 900px;'>
                <div>
                	<div><b>Sentence</b></div>
                	<div style='text-align:center'><b>1</b></div>
                	<div style='text-align:center'><b>2</b></div>
                	<div style='text-align:center'><b>3</b></div>
                	<div style='text-align:center'><b>4</b></div>
								</div>
                <div>
                	<div><b>Rule</b></div>
                	<div><input id='0' width='50px'/></div>
                	<div><input id='1' width='50px'/></div>
                	<div><input id='2' width='50px'/></div>
                	<div><input id='3' width='50px'/></div>
								</div>
                <div>
                	<div><b>Tense</b></div>
                	<div><input id='4' width='210px'/>,<input id='5' width='210px'/></div>
                	<div><input id='6' width='210px'/></div>
                	<div><input id='7' width='210px'/></div>
                	<div><input id='8' width='210px'/></div>
								</div>
              </div>

        `,
				answer: [
					`d`,
					`a`,
					`b`,
					`c`,
					`past continuous/past simple`,
					`past continuous / past simple`,
					`past continuous`,
					`past simple`,
					`past continuous`,
				],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E5',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E5/Key/answerKey.png',
		component: T6,
		inputSize: 210,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '5',
				title: `Complete the sentences using the correct forms of the past simple and past continuous.`,
				color: '#1cbed5',
				// prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
              <div style='display: flex;'>
                <div><b>1. &emsp;</b></div>
                <div>I # (listen) to the radio when I # (hear) my phone.</div>
              </div>
              <div style='display: flex;'>
                <div><b>2. &emsp;</b></div>
                <div>I # (watch) TV while the children # (play) outside.</div>
              </div>
              <div style='display: flex;'>
                <div><b>3. &emsp;</b></div>
                <div>I # (see) an accident while I # (cycle) to school.</div>
              </div>
              <div style='display: flex;'>
                <div><b>4. &emsp;</b></div>
                <div>My mum # (not shop) when I #(phone) her.</div>
              </div>
        `,
				answer: [
					`was listening`,
					`heard`,
					`was watching`,
					`were playing`,
					`saw`,
					`was cycling`,
					`wasn't shopping`,
					`phoned`,
				],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FP8-SB-demo-2023-U1-P15-E6',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E6/Key/answerKey.png',
		component: T6,
		inputSize: 210,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '6',
				title: `Work in pairs to ask and answer questions about a celebration you remember. Take turns to ask and answer questions 1–4. Then change roles.`,
				color: '#1cbed5',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],
		questionImage: [],
		hideBtnFooter: true,
		questions: [
			{
				title: `
              <div style='display: flex;'>
                <div>
									<div style='display: flex;'>
										<div><b>1. &emsp;</b></div>
										<div>When and where did the event happen? </div>
									</div>
									<div style='display: flex;'>
										<div><b>2. &emsp;</b></div>
										<div>What were you celebrating at the event?</div>
									</div>
									<div style='display: flex;'>
										<div><b>3. &emsp;</b></div>
										<div>Can you remember what you were wearing?</div>
									</div>
									<div style='display: flex;'>
										<div><b>4. &emsp;</b></div>
										<div>How did you feel at that time?</div>
									</div>
								</div>
                <div style='margin-left: 20px;'><img src='img/FriendsPlus/Page15/E6/1.png' width=60% /></div>
              </div>
								
        `,
				answer: [
					`was listening`,
					`heard`,
					`was watching`,
					`were playing`,
					`saw`,
					`was cycling`,
					`wasn't shopping`,
					`phoned`,
				],
			},
		],
	},
}

export default json
