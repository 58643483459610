import { Input } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Col } from "reactstrap";
import styles from "./case4.module.css";

const Case4 = ({
  column,
  commonStyle,
  content,
  contentStyle,
  handleContent,
  submitted,
  title,
  titleStyle,
  userAnswers,
}) => {
  return (
    <Col>
      <fieldset
        className={styles.container}
        style={{
          ...commonStyle,
          ...contentStyle,
        }}
      >
        <legend
          className={styles.title}
          style={{
            ...titleStyle,
          }}
        >
          {title}
        </legend>
        {content?.map((item, position) => {
          return item === "#" ? (
            <Input
              key={position}
              className={styles.input}
              onChange={(e) => handleContent(column, position, e.target.value)}
              style={{
                color: submitted
                  ? userAnswers.find(
                      (x) => x.column === column && x.position === position
                    )?.isCorrect
                    ? "green"
                    : "red"
                  : "black",
                ...contentStyle,
              }}
            />
          ) : (
            <div key={position} className={styles.text}>
              {item}
            </div>
          );
        })}
      </fieldset>
    </Col>
  );
};

Case4.propTypes = {
  column: PropTypes.number.isRequired,
  commonStyle: PropTypes.object,
  content: PropTypes.array.isRequired,
  contentStyle: PropTypes.object,
  handleContent: PropTypes.func,
  submitted: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  userAnswers: PropTypes.array,
};

export default Case4;
