import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P45-E1',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page45/key/E1answerKey.png',
    component: T6v2,
    // maxLength: 6,
    textareaStyle: { width: 730 },
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '1',

        title: `
		Read the product reviews. What things are good about each product? What things are bad about each product?
						`,
        color: 'rgb(1,169,81)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div class="container">
				<div><img src="img/FriendsPlus/Page45/10.jpg" width="1200px" height="450px"/></div>

				<div style="display: flex;  margin-top: 60px;">
					<span style="font-weight: bold; margin-right: 20px;">Good</span>
					<textarea id="0" rows="5"></textarea>
				</div>

				<div style="display: flex;  margin-top: 60px;">
					<span style="font-weight: bold; margin-right: 20px;">Bad</span>
					<textarea id="1" rows="5"></textarea>
				</div>
					
			</div>
						`,
        answer: [
          'Coconut sweets have different tastes and flavours for different people.  Coconut oil made from natural ingredients is safe for hair and skin. It prevents dandruff and makes hair much smoother.',
          'Coconut sweets cause tooth decay. Coconut oil is rather sticky.',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P45-E2',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        Work in pairs. Think of something you have bought recently and use the key phrases to talk about its advantages and disadvantages.
		`,
        color: 'rgb(1,169,81)',
        prefix: { icons: ['far fa-comment'], text: '' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div class="container">
			<div><img src="img/FriendsPlus/Page45/11.jpg"/></div>
		</div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P45-E3',
    audio: '',

    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page45/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 6,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '3',

        title: `
		Find the words in <span style="color:rgb(28,190,215);">blue</span> in the two reviews. What form of the verb follows to and in order to? When do we use clauses of purpose?
						`,
        color: 'rgb(1,169,81)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
			<div class="container">
				<div style="display: flex; margin-top: 20px;"><img src="img/FriendsPlus/Page45/10.jpg" width="1200px" height="500px" />
				</div>
				<h1 style="margin-top: 20px; margin-bottom: 20px;">ANSWERS</h1>
				<p style="font-size: 25px; margin-right: 20px; font-weight: bold;">1 What form of the verb follows to and in order to?</p>
				<p style="font-size: 25px; margin-right: 20px;">#</p>

				<p style="font-size: 25px; margin-right: 20px; font-weight: bold;">2 When do we use clauses of purpose?</p>
				<p style="font-size: 25px; margin-right: 20px;">#</p>
					


					
			</div>
						`,
        answer: [
          'The infinitive follows to and in order to.',
          'We use clauses of purpose to explain why.',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P45-E4',
    audio: '',

    video: '',
    // recorder: true,
    // exerciseKey: '',
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 6,
    textareaStyle: { width: '730px' },
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '4',

        title: `
		Complete the sentences with clauses of purpose and your own ideas.
						`,
        color: 'rgb(1,169,81)',
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
		<div class="container">

			<div style="display: flex; margin-top: 20px;">
				<span style="margin-right: 20px; font-weight: bold">1</span>
				<span style="margin-right: 20px; font-weight: bold">I went to town</span>
				<div style="margin-right: 20px;  margin-top: -5px; font-weight: bold"><textarea id="0" rows="5"></textarea></div>
			</div>

			

			<div style="display: flex; margin-top: 20px;">
				<span style="margin-right: 20px; font-weight: bold">2</span>
				<span style="margin-right: 20px; margin-bottom: -50px; font-weight: bold">She studied hard</span>
				<div style="margin-right: 20px; margin-top: -5px; font-weight: bold"><textarea id="1" rows="5"></textarea></div>
			</div>
	  </div>
						`,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FP8-SB-demo-2023-U4-P45-E5',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: '',
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
    },
    titleQuestion: [
      {
        num: '5',
        title: `
		Follow the steps in the Writing Guide.

		`,
        color: 'rgb(1,169,81)',
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div class="container">
		<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page45/12.jpg"/></div>

			
		</div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
