import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P93-E1",
    audio: "",
    video: "",
    component: T6v2,
    maxLength: 13,
    inputSize: 470,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Read paragraphs 1 and 2 of the text quickly
to find the words below. What part of speech
are they? Explain their meaning in your own
words.
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    hintBox: [
      {
        src: ["conditions", "label", "plantation", "price", "profit"],
        width: 610,
        borderColor: "#e22f3b",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						<div style='display:flex'>
              <div>
							  <hintbox id='0'></hintbox>
                <div style='margin-top:50px'>
                  <u style='font-weight:bold;font-style:italic'>Answer:</u>#
                </div>
              </div>
              <div>
                <img src='img/FriendsPlus/Page93/E1/1.jpg'>
              </div>
						</div>
						

			
									`,
        answer: [
          "The words are all nouns.",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P93-E2",
    audio: "",
    video: "",
    component: T6v2,
    // maxLength: 13,
    inputSize: 170,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page93/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				Read the article. What is Fairtrade?
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display:flex'>
						
						<img src='img/FriendsPlus/Page93/E2/1.jpg'>
            <div style='margin-left:20px'>
              <u style='font-weight:bold;font-style:italic'>Answers</u>
              <textarea id='0' rows='5'></textarea>
            </div>
					</div>
			
									`,
        answer: [
          "Fairtrade is an international movement which helps farmers and workers in developing countries work in good conditions and receive a fair price for their Fairtrade-labelled goods.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P93-E3",
    audio: "Audios/3.05.mp3",
    video: "",
    // recorder: true,
    component: T6v2,
    // maxLength: 5,
    inputSize: 100,
    textAlign: "center",
    stylesTextInput: {
      // fontSize: 19,
      background: "transparent",
      borderBottom: "dash",
    },
    exerciseKey: "img/FriendsPlus/Page93/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name="3.05"></headphone >
				Read and listen to the article
again. Write true or false. Correct the false
sentences.
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='display:flex'>
        <img src="img/FriendsPlus/Page93/E2/1.jpg"/>
        <div style='width:15cm;margin-left:20px;line-height:35px'>
          <b>1</b>&ensp;People in the UK don't grow bananas.  <textarea id='0' rows='2'></textarea>   
          <b>2</b>&ensp;Some banana farmers don’t earn enough
          because companies don’t pay a fair price. <textarea id='1' rows='2'></textarea>   
          <b>3</b>&ensp;Fairtrade only helps banana farmers. <textarea id='2' rows='2'></textarea>  
          <b>4</b>&ensp;Foncho buys bananas from farmers and
          sells them to supermarkets. <textarea id='3' rows='2'></textarea>   
          <b>5</b>&ensp;Thanks to the Fairtrade, Foncho's life is
          getting better now. <textarea id='4' rows='2'></textarea>
        </div>
      </div>
		
			
									`,
        answer: ["true",
        "true",
        "false (Coffee, sugar, cocoa, rice and cotton are also Fairtrade products.)"
        ,"false (Foncho is a banana farmer who belongs to a farmers’ co-operative.)",
        "true"],
      },
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P93-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in groups. Make a poster
        about Fairtrade. Include pictures. `,
        color: "#e22f3b",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page93/E4/1.jpg",
        },
      ],
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P93-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Create a poster
        to show what happens in the water cycle.<br>
        Include information from this page.`,
        color: "#e22f3b",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
          
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
