import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	1: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P46-E1",
		audio: "",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page46/key/E1answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 150,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			textAlign: "center",
			fontSize: "24px",
		},
		titleQuestion: [
			{
				num: "1",

				title: `
			Match the words with the definitions.
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
		<div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">desert</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">falls</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">dune</p>


			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">cave</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">ocean</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">valley</p>
			
		</div>

		<div style="display: flex; flex-wrap: wrap">
			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
			<span style="font-weight: bold">1 </span>
			<span>where river water runs over the edge of a
			cliff #</span>
			</p>

			<p style="font-size: 25px; margin-top: 20px;margin-right: 20px">
			<span style="font-weight: bold">2 </span>
			<span>where a place with very little rain #</span>
			</p>

			<p style="font-size: 25px; margin-top: 20px;margin-right: 20px">
			<span style="font-weight: bold">3 </span>
			<span>a sandy hill in the desert or on the beach #</span>
			</p>

			<p style="font-size: 25px; margin-top: 20px;margin-right: 20px">
			<span style="font-weight: bold">4 </span>
			<span>low land, with hills around it #</span>
			</p>

			<p style="font-size: 25px; margin-top: 20px;margin-right: 20px">
			<span style="font-weight: bold">5 </span>
			<span>a hole in the side of a hill or underground #</span>
			</p>

			<p style="font-size: 25px; margin-top: 20px;margin-right: 20px">
			<span style="font-weight: bold">6 </span>
			<span>an extremely large area of salty water #</span>
			</p>

			
		</div>
		
	</div>
							`,
				answer: ["falls", "desert", "dune", "valley", "cave", "ocean"],
			},
		],
	},
	2: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P46-E2",
		audio: "",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page46/key/E2answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			textAlign: "center",
			fontSize: "24px",
			fontWeight: "bold",
		},
		titleQuestion: [
			{
				num: "2",

				title: `
		Complete the words.
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<div style="display: flex; flex-wrap: wrap;margin-top: 40px;">
				<div>
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px"
					>
					1
					</span>
					<span style="font-size: 25px">
						<span style="margin-right: 10px; font-size: 25px"
						>We're planning to</span
						>
						<span style="font-weight: bold; font-size: 25px">s</span>
						<span>#</span>
						<span>#</span>
						<span style="font-weight: bold; font-size: 25px">o</span>
						<span>#</span>
						<span>#</span>
						<span style="font-size: 25px">on Sunday
						morning. There's little traffic at this time.</span>
					</span>
				</div>
				
				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					2
					</span>

					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">Can you</span>
					<span style="font-weight: bold; font-size: 25px">s</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-weight: bold; font-size: 25px">o</span>
					<span>#</span>
					<span>#</span>
					<span style="font-size: 25px"
						>at the next petrol  station? I want to buy something to drink</span>
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					3
					</span>

					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">The students were talking, so the teacher decided not to</span>
					
					<span style="font-weight: bold; font-size: 25px">c</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-weight: bold; font-size: 25px">o</span>
					<span>#</span>
					<span style="font-size: 25px">until everyone was quiet. </span>
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					4
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">When we moved out of Brighton, I had to</span>
					<span style="font-weight: bold; font-size: 25px">I</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-weight: bold; font-size: 25px">b</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-size: 25px">all my school friends. </span>
						
					</span>
				</div>

				<div style="margin-top: 20px;">
					<span style="font-weight: bold; margin-right: 10px; font-size: 25px">
					5
					</span>
					<span style="font-size: 25px">
					<span style="margin-right: 10px; font-size: 25px">My grandparents used to</span>
					<span style="font-weight: bold; font-size: 25px">s</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-weight: bold; font-size: 25px">w</span>
					<span>#</span>
					<span>#</span>
					<span>#</span>
					<span style="font-size: 25px"> us when my parents went on business trips.</span>
					</span>
				</div>




			</div>
		</div>
							`,
				answer: [
					"e",
					"t",
					"f",
					"f",
					"t",
					"o",
					"p",
					"f",
					"f",
					"a",
					"r",
					"r",
					"y",
					"n",
					"e",
					"a",
					"v",
					"e",
					"e",
					"h",
					"i",
					"n",
					"d",
					"t",
					"a",
					"y",
					"i",
					"t",
					"h",
				],
			},
		],
	},
	3: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P46-E3",
		audio: "",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page46/key/E3answerKey.png",
		component: T6v2,
		// maxLength: 6,
		inputSize: 300,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			textAlign: 'center',
			fontSize: "24px",
		},
		titleQuestion: [
			{
				num: "3",

				title: `
		Complete the sentences using the present perfect simple or continuous form of the
		verbs.
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">1 </span>
				<span style="font-size: 25px">He </span>
				# a bungee jump. (never / do)
			</p>

			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">2 </span>
				<span style="font-size: 25px">We </span>
				# for long. We started a week ago. (not learn)
			</p>

			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">3 </span>
				<span style="font-size: 25px">They </span>
				# to surf. (always / want)
			</p>

			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">4 </span>
				<span style="font-size: 25px">How many times </span>
				# to England? (you / be)
			</p>

			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">5 </span>
				<span style="font-size: 25px">I </span>
				# all morning, and my homework still isn't finished. (work)
			</p>

			<p style="font-size: 25px; margin-top: 10px;margin-right: 20px">
				<span style="font-weight: bold; font-size: 25px">6 </span>
				<span style="font-size: 25px">She </span>
				# for a month now. She'll be home next week. (travel)
			</p>
		
		
		
		</div>
							`,
				answer: [
					"'s never done",
					"haven't been learning",
					"'ve always wanted",
					"have you been",
					"'ve been working",
					"'s been travelling",
				],
			},
		],
	},
	4: {
		unit: "Unit 4",
		id: "FP8-SB-demo-2023-U4-P46-E4",
		audio: "Audios/1.37.mp3",

		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page46/key/E4answerKey.png",
		component: T6v2,
		maxLength: 1,
		inputSize: 40,
		stylesTextInput: {
			// background: 'transparent',
			// border: 'none',
			//textAlign: 'center',
			fontSize: "24px",
			fontWeight: "bold",
		},
		titleQuestion: [
			{
				num: "4",

				title: `
		<headphone name="1.37"></headphone >
		Listen to Oscar telling Lily about John Goddard. Which of the things below did John
		Goddard do, and which are Lily's plans? Write J for John Goddard or L for Lily. 
							`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		// hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
				
		<div class="container">
			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">climb up the world's highest mountains </span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">cycle around Australia </span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">dive off cliffs in Hawaii </span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">hike across the Grand Canyon</span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px"> jump out of a plane with a parachute</span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">kayak down the longest rivers</span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">learn three foreign languages </span>
			</p>

			<p style="font-size: 25px; margin-top: 40px;margin-right: 20px">
				<span ># </span>
				<span style="font-size: 25px">sail around the world</span>
			</p>

			
		</div>
							`,
				answer: ["J", "L", "L", "J", "J", "J", "J", "L"],
			},
		],
	},
};

export default json;
