import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	12: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P29-E12',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/E12/Key/answerKey.png',
		component: T6v2,
		inputSize: 140,
		stylesTextInput: {
			borderStyle: 'dotted',
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '12',
				title: `
					Complete the dialogues with the present perfect 
					or past simple form of the verbs in brackets.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style='width: 700px; display: flex; flex-direction: column; gap: 10px;'>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Ruth</div>
							<div>
								<span style='font-style: italic; border-bottom: 2px dotted rgb(204, 204, 204);'>Have you ever broken</span> (you / ever / break) something really expensive?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Oscar</div>
							<div>
								Yes, I <sup>1</sup> # . I <sup>2</sup> # (be) three or four years ago. 
								I <sup>3</sup> # (drop) my dad’s laptop. 
								He <sup>4</sup> # (not let) me use his tablet or smartphone 
								since it <sup>5</sup> # (happen)!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Alex</div>
							<div>
								<sup>6</sup> <input id='7' width='230px' /> (you / ever / meet) a celebrity?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Sara</div>
							<div>
								No, I <sup>7</sup> #, but my uncle <sup>8</sup> # . 
								He <sup>9</sup> # (go) to school with Keira Knightley, the actress. 
								But she <sup>10</sup> # (not be) famous then, of course. 
								They <sup>11</sup> # (be) only six years old!
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Alex</div>
							<div>
								<sup>12</sup> <input id='11' width='180px' /> (he / see) her since she <sup>13</sup> # 
								(become) famous?
							</div>	
						</div>
						<div style='display: flex; gap: 20px;'>
							<div style='font-weight: bold; width: 80px;'>Sara</div>
							<div>
								No, he <sup>14</sup> # .
							</div>	
						</div>
					</div>
        `,
				answer: [
					`have`,
					`was`,
					`dropped`,
					`hasn't let`,
					`happened`,
					`Have you ever met`,
					`haven't`,
					`has`,
					`went`,
					`wasn't`,
					`were`,
					`Has he seen`,
					`became`,
					`hasn't`,
				],
			},
		],
	},
	13: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P29-E13',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page29/E13/Key/answerKey.png',
		titleQuestion: [
			{
				num: '13',
				title: `
					Choose the correct words. Then practise with your partner.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: '-40px',
					fontWeight: 600,
					fontStyle: 'italic',
					color: '',
					// fontSize: 27,
					borderRadius: '50%',
					// marginInline: '-1px',
				},
				selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
				limitSelect: 1,
				listWordsStyle: {},
				listWords: [
					`’ll / are / have`,
					`hear / hearing / heard`,
					`great / fun / interesting`,
					`want / fancy / try`,
					`try / want / fancy`,
					`Can’t / Will / Are`,
				],
				answers: ['0-0', '1-8', '2-4', '3-4', '4-0', '5-0'],
				initialValue: [],
			},
			Layout: `
				<div style='display: flex; flex-direction: column; gap: 10px;'>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Zach</div>
						<div>
							Hey, Dan. Have you seen this horror film, <i>Zombies Attack</i> ?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Dan</div>
						<div>
							I’ve never seen a horror film.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Zach</div>
						<div>
							You’ve never seen a horror film? 
							Seriously? I think you <sup>1</sup> <input id='0' type='Circle' /> enjoy this. 
							I've <sup>2</sup> <input id='1' type='Circle' /> it's good.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Dan</div>
						<div>
							It doesn’t sound much <sup>3</sup> <input id='2' type='Circle' /> to me. 
							I don’t like scary films. Can we have dinner first?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Zach</div>
						<div>
						OK. What do you <sup>4</sup> <input id='3' type='Circle' /> eating?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Dan</div>
						<div>
							Why not <sup>5</sup><input id='4' type='Circle' /> something different? 
							What about some Indian food?
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Zach</div>
						<div>
							<sup>6</sup> <input id='5' type='Circle' /> we have pizza? 
							I’ve never had Indian food.
						</div>	
					</div>
					<div style='display: flex; gap: 20px;'>
						<div style='font-weight: bold; width: 80px;'>Dan</div>
						<div>
							Really? It’s delicious.
						</div>	
					</div>
				</div>
			  `,
		},
	},
	14: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P29-E12',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/E14/Key/answerKey.png',
		component: T6v2,
		hintBox: [
			{
				src: [
					`absolutel`,
					`one of the most`,
					`quite an`,
					`really delicious`,
					`to seeing`,
					`to`,
					`stop`,
					`very`,
					`visiting`,
				],
				borderColor: 'rgb(110, 110, 112)',
				width: 480,
			},
		],
		inputSize: 200,
		stylesTextInput: {
			borderStyle: 'dotted',
			fontSize: '24px',
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '14',
				title: `
					Read the advert and complete the text with the words.
				`,
				color: 'rgb(78, 59, 151)',
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<hintBox id='0'></hintBox>
					<div style='margin-top: 20px;'><img src='img/FriendsPlus/Page29/E14/1.jpg' width='50%' /></div>
					<div style='margin-top: 10px; width: 1000px; padding: 20px; border: 1px solid rgb(110, 110, 112); box-shadow: 7px 7px 5px rgb(110, 110, 112);'>
						<div style='font-weight: bold;'>St Nicholas Market</div>
						<div>
						My favourite place in Bristol is St Nicholas Market. 
						It’s <sup>1</sup> # amazing markets in Britain, and 
						you can almost feel its long history. It’s definitely 
						worth <sup>2</sup> # . When you enter, you see 
						hundreds of colourful products, from vintage 
						clothes and jewellery to local arts and crafts. You can 
						smell <sup>3</sup> # street food like Indian curry or 
						Algerian falafel. I love walking between the stalls, 
						watching people and seeing what they buy. It’s 
						<sup>4</sup> # experience. But what makes this place 
						really special is an <sup>5</sup> # wonderful glass 
						roof over the stalls. 
						If you ever come to Bristol, don’t forget <sup>6</sup> # 
						by at St Nicholas Market and have lunch. Take time 
						to look for souvenirs (or up at the sky) with a cup 
						of <sup>7</sup> # good coffee in your hand. We’re 
						looking forward <sup>8</sup> # you there.
						</div>
					</div>
        `,
				answer: [
					`one of the most`,
					`visiting`,
					`really delicious`,
					`quite an`,
					`absolutely`,
					`to stop`,
					`very`,
					`to seeing`,
				],
			},
		],
	},
}

export default json
