import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
	8: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P88-E8",
		audio: "",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page88/key/E8answerKey.png",
		component: T6v2,
		maxLength: 1,
		inputSize: 40,
		stylesTextInput: {
			background: "transparent",
			border: "none",
			fontSize: "22px",
			fontWeight: "bold",
			textAlign: "center",
		},
		titleQuestion: [
			{
				num: "8",
				title: `
			Complete the film and book words and solve the mystery word.
											`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
		<div style="margin-left: 100px;position: relative;">
			<img src="img/FriendsPlus/Page88/8.jpg" />

			<div style="position: absolute;top:5px;left:95px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:5px;left:132px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:5px;left:208px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:5px;left:244px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:22px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:59px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:45px;left:322px">
				<p style="font-size: 25px">#</p>
			</div>
			
			<div style="position: absolute;top:85px;left:59px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>
			
			<div style="position: absolute;top:85px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:85px;left:322px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:125px;left:59px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:125px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:125px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:125px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:125px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:322px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:163px;left:359px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:59px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:201px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:59px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:239px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>
			
			<div style="position: absolute;top:277px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:322px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:277px;left:359px">
				<p style="font-size: 25px">#</p>
			</div>
			
			<div style="position: absolute;top:315px;left:96px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:315px;left:134px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:315px;left:172px">
				<p style="font-size: 25px">#</p>
			</div>
			
			<div style="position: absolute;top:315px;left:210px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:315px;left:246px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:315px;left:284px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute;top:315px;left:322px">
				<p style="font-size: 25px">#</p>
			</div>

			<div style="margin-top: 30px">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span style="font-size: 25px">film about difficult relationships</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span style="font-size: 25px">exciting film with new experiences and
				places</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span style="font-size: 25px">exciting film that can be scary</span>
			</div>
			
			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span style="font-size: 25px">the police try to catch criminals</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span style="font-size: 25px">there is often fighting, cars and guns</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">6</span>
				<span style="font-size: 25px">it’s difficult to know what’s happened</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">7</span>
				<span style="font-size: 25px">the actors sing and dance</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">8</span>
				<span style="font-size: 25px">love story</span>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">9</span>
				<span style="font-size: 25px">film about unreal worlds and characters
				Mystery word:.................</span>
			</div>




		</div>
	
											`,
				answer: [
					"D",
					"R",
					"M",
					"A",
					"A",
					"D",
					"V",
					"E",
					"N",
					"T",
					"U",
					"R",
					"E",
					"T",
					"H",
					"R",
					"I",
					"L",
					"L",
					"E",
					"R",
					"C",
					"R",
					"I",
					"M",
					"E",
					"A",
					"C",
					"T",
					"I",
					"O",
					"N",
					"M",
					"Y",
					"S",
					"T",
					"E",
					"R",
					"Y",
					"M",
					"U",
					"S",
					"I",
					"C",
					"A",
					"L",
					"R",
					"O",
					"M",
					"A",
					"N",
					"C",
					"E",
					"F",
					"A",
					"N",
					"T",
					"A",
					"S",
					"Y",
				],
			},
		],
	},
	9: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P88-E9",
		audio: "",
		video: "",
		// recorder: true,
		exerciseKey: "img/FriendsPlus/Page88/key/E9answerKey.png",
		component: T6v2,
		// maxLength: 1,
		inputSize: 150,
		stylesTextInput: {
			//   background: "transparent",
			//   border: "none",
			fontSize: "22px",
			fontWeight: "bold",
			textAlign: "center",
		},
		titleQuestion: [
			{
				num: "9",
				title: `
		Complete the sentences with the missing letters.
											`,
				color: "rgb(121,106,175)",
			},
		],
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
		<div>
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span style="font-size: 25px">My new school is h #. There are three
			separate buildings and about eighty
			classrooms. </span>
		</div>

		<div style="margin-top: 10px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span style="font-size: 25px">I read an i # story about a girl
			who sailed around the world.</span>
		</div>

		<div style="margin-top: 10px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span style="font-size: 25px">I love c # novels like Oliver Twist.</span>
		</div>

		<div style="margin-top: 10px">
			<span style="font-weight: bold; margin-right: 10px">4</span>
			<span style="font-size: 25px">He’s a t # actor. I don’t
			understand why people like him.</span>
		</div>
	
											`,
				answer: ["uge", "ncredible", "lassic", "errible"],
			},
		],
	},
	10: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P88-E10",
		audio: "",
		video: "",
		exerciseKey: "img/FriendsPlus/Page88/key/E10answerKey.png",
		titleQuestion: [
			{
				num: "10",
				title: `
        Choose the correct words.
						`,
				color: "rgb(121,106,175)",
			},
		],
		component: Circle_Write,
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 600,
					//   textAlign: "center",
					fontSize: 22,
					color: "black",
					//   maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					// padding: "-40px",
					color: "",
					borderRadius: "50%",
					fontWeight: "700",
					fontSize: "22px",
					// width: "200px",
				},
				selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
				limitSelect: 1,
				listWords: [
					"awarded / awarding / won",
					"don't / isn't / aren't",
					"unemployed / don't_employ / are_unemployed",
					"didn't / wasn't / weren't",
					"don't_show / aren't_show / aren't_shown",
					"by / from / of",
				],

				answers: ["0-0", "1-4", "2-8", "3-4", "4-8", "5-0"],
				initialValue: {},
			},
			Layout: `
	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span style="font-size: 25px">This film was <input id='0' type='Circle' style="letter-spacing: 10px;"/>
			two Oscars last year.</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span style="font-size: 25px"> This type of Blu-ray disc <input id='1' type='Circle' style="letter-spacing: 10px;"/> sold in the UK</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span style="font-size: 25px"> Many actors <input id='2' type='Circle' style="letter-spacing: 10px;"/>  most of the time</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">4</span>
			<span style="font-size: 25px">  She <input id='3' type='Circle' style="letter-spacing: 10px;"/> given a role in
			the new film.</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">5</span>
			<span style="font-size: 25px">  Independent films <input id='4' type='Circle' style="letter-spacing: 10px;"/>  in a lot of cinemas.</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">6</span>
			<span style="font-size: 25px">  The first Harry Potter book was rejected
			<input id='5' type='Circle' style="letter-spacing: 10px;"/>  many publishers before it
			was successful</span>
		</div>
	  
					`,
		},
	},
	11: {
		unit: "Unit 8",
		id: "FP8-SB-demo-2023-U8-P88-E11",
		audio: "Audios/2.36.mp3",
		video: "",
		exerciseKey: "img/FriendsPlus/Page88/key/E11answerKey.png",
		component: Circle_Write,
		titleQuestion: [
			{
				num: "11",
				title: `
		<headphone name="2.36"></headphone >
        Listen to a lesson about films. Choose the correct words.
						`,
				color: "rgb(121,106,175)",
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 600,
					//   textAlign: "center",
					fontSize: 22,
					color: "black",
					//   maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					// padding: "-40px",
					color: "",
					borderRadius: "50%",
					fontWeight: "700",
					fontSize: "22px",
					// width: "200px",
				},
				selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
				limitSelect: 1,
				listWords: [
					"written / popular",
					"director / actor",
					"likes / doesn't_like",
					"great / famous",
					"actors / directors",
					"Hollywood / foreign",
				],

				answers: ["0-0", "1-0", "3-4", "2-0", "4-0", "5-0"],
				initialValue: {},
			},
			Layout: `
	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span style="font-size: 25px">Ben likes science fiction books which were
			<input id='0' type='Circle' style="letter-spacing: 10px;"/>  a long time ago. </span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span style="font-size: 25px">  Ben wants to see how the <input id='1' type='Circle' style="letter-spacing: 10px;"/> 
			presents the world in the future. </span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span style="font-size: 25px"> Ben <input id='2' type='Circle' style="letter-spacing: 10px;"/>  watching films
			which were adapted from a book he’s read
			before.</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">4</span>
			<span style="font-size: 25px">Katie says actors become <input id='3' type='Circle' style="letter-spacing: 10px;"/> 
			when they’re the winner of an award or
			when they receive an Oscar nomination</span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">5</span>
			<span style="font-size: 25px">Katie doesn’t want to see the same <input id='4' type='Circle' style="letter-spacing: 10px;"/>  all the time. </span>
		</div>

	  	<div style="margin-top: 15px">
			<span style="font-weight: bold; margin-right: 10px">6</span>
			<span style="font-size: 25px">Tom thinks stars in <input id='5' type='Circle' style="letter-spacing: 10px;"/> 
			films look like supermodels.</span>
		</div>
	  
					`,
		},
	},
};

export default json;
