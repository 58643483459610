import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E1/Key/answerKey.png",
    component: T6,
    stylesTextInput:{background:"none",color:"black"},
    textAlign:"center",
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
					Complete the table with the words in <span style='color: rgb(0, 173, 238);'>blue</span> in the 
					quiz. Then compare your answers with a partner's.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    questions: [
      {
        title: `
					<div style='display: flex; margin-bottom:-50px'>
						<div><img src='img/FriendsPlus/Page30/E1/1.jpg' width='100%' /></div>
						<div><img src='img/FriendsPlus/Page30/E1/2.jpg' width='100%' /></div>
					</div>	
          <div style='border:3px solid rgb(28 74 151); display:flex;width:16cm;background:rgb(206 218 240)'>
            <div style=' width:8cm;border-right:3px solid rgb(28 74 151)'>
              <div style='border-bottom:3px solid rgb(28 74 151);background:rgb(126 166 217); text-align:center;color:white'>Land</div>
              <div style='padding:0px 10px'>#<br>#<br>#<br>#<br>#<br>#<br></div>
            </div>
            <div style=' width:8cm'>
              <div style='border-bottom:3px solid rgb(28 74 151);background:rgb(126 166 217); text-align:center;color:white'>water</div>
              <div style='text-align:center; padding:0px 10px'>sea<br>#<br>#<br>#<br>#<br></div>
            </div>
            
          </div>
				`,
        answer: ["valley","cave","rainforest","beaches","dunes","desert","river","falls","waves","ocean"],
      },
    ],
   
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E2",
    audio: "Audios/1.23.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/answerKey.png",
    component: T6v2,
    inputSize: 34,
    maxLength: 1,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.23'></headphone >
					Do the quiz with a partner. Then listen and check your answers.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; margin-bottom: -30px'>
						<div><img src='img/FriendsPlus/Page30/E1/1.jpg' width='100%' /></div>
						<div><img src='img/FriendsPlus/Page30/E1/2.jpg' width='100%' /></div>
					</div>

					<div style='display: flex; gap: 20px;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>#</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>#</div>
						</div>
					</div>
				`,
        answer: ["c", "b", "b", "c", "b", "a"],
      },
    ],
  },
};

export default json;
