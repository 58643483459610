import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E1",
    audio: "Audios/2.17.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    hintBox: [{
      src: ["anyone", "difficult", "late", "matter", "right", "upset", "worse"],
      width: 800,
      borderColor: "rgb(220,30,116)"
    }],

    inputSize: 170,
    textAlign: "center",
    // stylesTextInput: {
    //   background: "transparent",
    //   border: "none",
    //   //   borderBottom: '1px solid black',
    //   fontSize: "20px",
    //   // fontWeight: "bold",
    // },
    titleQuestion: [
      {
        num: "1",

        title: `
				<headphone name="2.17"></headphone >
				Complete the dialogue with the words in the box. Then watch or listen and
				check. What’s Neil’s problem? What does Daisy advise Neil to do?
										`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
        <div style='display:flex'>
          <b>
            Daisy<br>   
            Neil <br><br>
                 Daisy<br>     
            Neil <br>
                  Daisy<br>
            Neil <br><br>
                  Daisy<br>
            Neil <br>
                  Daisy <br>
          </b>
          <div style='margin-left:20px;width: 25cm'>
               Hi, Neil. Is anything the <sup>1</sup># ? You 
               look really <sup>2</sup># .
               <br> I’m really concerned about the 
               engineering course I’m doing. It’s too 
               <sup>3</sup># and I’m really not enjoying it. 
               I know I’m not going to pass.
               <br> Have you spoken to your teachers about it?
               <br> No. I’m so worried, I haven’t told 
               <sup>4</sup># . What would you do if you 
               were me?
               <br> My advice is to get help now. If you don’t, 
               things will only get <sup>5</sup># .
               <br> I don’t know what to do. I think an 
               apprenticeship would be better for me, 
               but it’s too <sup>6</sup># now.
               <br> Don’t panic. It’s never too late, but you 
               need to speak to someone about it.
               <br> You’re <sup>7</sup># . I’ll go and talk to my 
               teacher now. Thanks, Daisy.
               <br>No problem.
          </div>
        </div>
        
				What’s Neil’s problem?
				<div><input id='7' font-size='25px' width='700px' border-bottom='1px solid black'></input></div>
				 What does Daisy advise Neil to do?
				<div><input id='8' font-size='25px' width='700px' border-bottom='1px solid black'></input></div>
				
			</div>
					
										`,
        answer: [
          "matter",
          "upset",
          "difficult",
          "anyone",
          "worse",
          "late",
          "right",
          "Neil thinks he isn't going to pass his engineering course.",
          "Daisy says he should talk to someone about it.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E2",
    audio: "Audios/2.17.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 600,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
				<headphone name="2.17"></headphone >
				Watch or listen again and underline the phrases Daisy and Neil use to:
										`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div><img src="img/FriendsPlus/Page64/12.jpg" width="1200px"/></div>

		<p style="font-size: 25px;margin-top:30px">
			<span style="font-weight: bold;margin-right:10px;" >1</span>
			<span>express feelings.</span><br/>#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px;" >2</span>
			<span>ask for advice</span><br/>#
		</p>

		<p style="font-size: 25px;">
			<span style="font-weight: bold;margin-right:10px;" >3</span>
			<span>give advice</span><br/>#
		</p>

		
			
					
										`,
        answer: [
          "I'm really concerned; I'm really not enjoying; I'm so worried",
          "What would you do (if you were me)?",
          "Don't panic; My advice is",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 550,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
				Cover the dialogue in exercise 1 and read the Key Phrases. Which two key phrases aren’t in
        the dialogue?
 
										`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/13.jpg"/></div>
    <h1 style="margin-top: 30px">Answers</h1>
    <p style="font-size: 25px;">#</p>
        
      

										`,
        answer: ["What should I do?, Can you give me any advice?"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Practise the dialogue.
        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 25px;"><img src="img/FriendsPlus/Page64/12.jpg" width="1200px"/></div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Ask for and give advice for each situation. Which piece of
        advice was the best?

        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >1</span>
          <span>I cheated in a test.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >2</span>
          <span>Another student is bullying my best friend.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >3</span>
          <span>I’m always late for school.</span>
        </p>
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/14.jpg" /></div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P64-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Read the situation, prepare and practise a new dialogue. Use the key phrases and the
        dialogue in exercise 1 to help you.
        `,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/15.jpg" /></div>
        <div style="margin-left: 200px;margin-top: 30px"><img src="img/FriendsPlus/Page64/13.jpg" /></div>
        <div style="margin-top: 30px"><img src="img/FriendsPlus/Page64/12.jpg" width="1200px"/></div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
