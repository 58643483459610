import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P87-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page87/key/E5answerKey.png",
    component: T6v2,
    maxLength: 255,
    inputSize: 600,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Complete the second sentence so that it means the same as the first sentence.
										`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;">1</span>
					<span style="font-size: 25px"> It’s hot, so we’re drinking cold water. We wouldn’t be drinking cold water
          <br/> # </span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">2</span>
					<span style="font-size: 25px">
					It isn’t raining, so I’m not wearing a coat. If it was raining, <br/> #
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">3</span>
					<span style="font-size: 25px">
					 Clara isn’t at school because she’s ill. If Clara wasn’t ill, <br/> #

					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">4</span>
					<span style="font-size: 25px">
					  He’s a student, so he hasn’t got a job. He’d have a job <br/> #
						</span>
						
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">5</span>
					<span style="font-size: 25px">
					She can’t text you because she’s busy. She could text you <br/> #
						</span>
						
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">6</span>
					<span style="font-size: 25px">
					Anton can’t hear you because he’s asleep. If Anton wasn’t asleep, <br/> #
						</span>
						
					</span>
				</div>

				<div style="margin-top:30px">
					<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">7</span>
					<span style="font-size: 25px">
					We don’t have enough time to visit another museum. If we had more time, <br/> #
						</span>
						
					</span>
				</div>

										`,
        answer: [
          "if it wasn't hot.",
          "I'd wear a coat.",
          "she'd be at school.",
          "if he wasn't a student.",
          "if she wasn't busy.",
          "he could hear you / he would be able to hear you.",
          "we would visit another museum.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P87-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page87/key/E6answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 120,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Complete the conversation with the words. Then practise with your partner. There are
        two extra words.
										`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

        <div style="border: 2px solid rgb(121,106,175); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;width:800px; margin-left:150px;">
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">better</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">healthier</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">make</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">problem</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">reason</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">suppose</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">survey</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">worse</p>
        </div>

        <div class="container">

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:30px">
            <div class="col-1">
              <span style="font-weight: bold">Katy</span>
            </div>
            <div class="col-10">
              <span >Excuse me, do you have a moment
              to help me with a <sup>1</sup> # ?
              </span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Usain</span>
            </div>
            <div class="col-10">
              <span >What’s it about?</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Katy</span>
            </div>
            <div class="col-10">
              <span >It’s about junk food. We want the
              government to <sup>2</sup> # it more
              expensive.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Usain</span>
            </div>
            <div class="col-10">
            <span> More expensive? Why? Isn’t it
            <sup>3</sup> # if food is cheaper?</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Katy</span>
            </div>
            <div class="col-10">
            <span >No, it isn’t. Junk food is unhealthy.
            The main <sup>4</sup> # for making it
            more expensive is to make people
            buy <sup>5</sup> # , cheaper food.   </span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Usain</span>
            </div>
            <div class="col-10">
            <span >I <sup>6</sup> # you’re right. OK, I’ll
            answer your questions.</span>
            </div>
          </div>

         </div>


				

										`,
        answer: ["survey", "make", "better", "reason", "healthier", "suppose"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P87-E7",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page87/key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `
        Choose the correct words.
						`,
        color: "rgb(121,106,175)",
      },
    ],
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    // isHiddenCheck: true,
    totalInput: 8,

    questionImage: [
      [{ url: "img/FriendsPlus/Page87/E7/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page87/E7/2.jpg" },
        { url: "img/FriendsPlus/Page87/E7/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page87/E7/4.jpg" },
        { url: "img/FriendsPlus/Page87/E7/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/6.jpg" },
        { url: "img/FriendsPlus/Page87/E7/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page87/E7/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/9.jpg" },
        { url: "img/FriendsPlus/Page87/E7/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page87/E7/11.jpg" },
        { url: "img/FriendsPlus/Page87/E7/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page87/E7/13.jpg" },
        { url: "img/FriendsPlus/Page87/E7/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/16.jpg" },
        { url: "img/FriendsPlus/Page87/E7/17.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/18.jpg" },
        { url: "img/FriendsPlus/Page87/E7/19.jpg", input: 3 },
        { url: "img/FriendsPlus/Page87/E7/20.jpg" },
        { url: "img/FriendsPlus/Page87/E7/21.jpg", input: 3 },
        { url: "img/FriendsPlus/Page87/E7/22.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/23.jpg" },
        { url: "img/FriendsPlus/Page87/E7/24.jpg", input: 4 },
        { url: "img/FriendsPlus/Page87/E7/25.jpg" },
        { url: "img/FriendsPlus/Page87/E7/26.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/27.jpg" },
        { url: "img/FriendsPlus/Page87/E7/28.jpg", input: 4 },
        { url: "img/FriendsPlus/Page87/E7/29.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/30.jpg" },
        { url: "img/FriendsPlus/Page87/E7/31.jpg", input: 5 },
        { url: "img/FriendsPlus/Page87/E7/32.jpg" },
        { url: "img/FriendsPlus/Page87/E7/33.jpg", input: 5 },
        { url: "img/FriendsPlus/Page87/E7/34.jpg" },
        { url: "img/FriendsPlus/Page87/E7/35.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/36.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/37.jpg" },
        { url: "img/FriendsPlus/Page87/E7/38.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/39.jpg" },
        { url: "img/FriendsPlus/Page87/E7/40.jpg", input: 6 },
        { url: "img/FriendsPlus/Page87/E7/41.jpg" },
        { url: "img/FriendsPlus/Page87/E7/42.jpg", input: 6 },
        { url: "img/FriendsPlus/Page87/E7/43.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/44.jpg" },
        { url: "img/FriendsPlus/Page87/E7/45.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/46.jpg" },
        { url: "img/FriendsPlus/Page87/E7/47.jpg", input: 7 },
        { url: "img/FriendsPlus/Page87/E7/48.jpg" },
        { url: "img/FriendsPlus/Page87/E7/49.jpg", input: 7 },
        { url: "img/FriendsPlus/Page87/E7/50.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page87/E7/51.jpg" },
        { url: "img/FriendsPlus/Page87/E7/52.jpg", input: 8 },
        { url: "img/FriendsPlus/Page87/E7/53.jpg" },
        { url: "img/FriendsPlus/Page87/E7/54.jpg", input: 8 },
        { url: "img/FriendsPlus/Page87/E7/55.jpg" },
        { url: "img/FriendsPlus/Page87/E7/56.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page87/E7/57.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page87/E7/58.jpg" }],
    ],
  },
};

export default json;
