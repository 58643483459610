import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P76-E1",
    audio: "Audios/2.27.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page76/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
			<headphone name="2.27"></headphone >
			Complete the dialogue with the words in the box. Then listen and check.
			What does Louise want the government to do? Does Hugo agree with her in the end?
							`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="border: 2px solid rgb(220,30,116); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;width:1200px">
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">effort</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">everyone</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">organise</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">rubbish</p>

			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">survey</p>
			<p style="font-size: 25px; margin-right: 40px; font-weight:bold">work</p>
		</div>
		<div style="margin-top:25px;position: relative;">
			<img src="img/FriendsPlus/Page76/12.jpg" width="1200px" />

			<div style="position: absolute;top:42px;left:202px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:154px;left:169px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:265px;left:334px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:433px;left:130px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:518px;left:127px">
				<span>#</span>
			</div>

			<div style="position: absolute;top:548px;left:166px">
				<span>#</span>
			</div>
			
		</div>

		<div style="margin-top: 30px;">
			<span style="font-weight: bold;">What does Louise want the government to
			do?</span>
			<div><textarea id="6" rows="2"></textarea></div>
		</div>

		<div>
			<span style="font-weight: bold;">Does Hugo agree with her in the end?</span>

			<div><textarea id="7" rows="2"></textarea></div>
		</div>
		
							`,
        answer: [
          "survey",
          "rubbish",
          "everyone",
          "organise",
          "work",
          "effort",
          "Louise wants the government to make it necessary for people to recycle.",
          "Yes, Hugo agrees with her in the end.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P76-E2",
    audio: "Audios/2.27.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page76/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
			<headphone name="2.27"></headphone >
			Cover the dialogue and complete the Key Phrases. Watch or listen again and
			check.
							`,
        color: "rgb(220,30,116)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 50px;position: relative;">
			<img src="img/FriendsPlus/Page76/13.jpg" width="1000px"/>
			<div style="position: absolute;top:185px;left:458px">
				<span >#</span>
			</div>

			<div style="position: absolute;top:236px;left:619px">
				<span >#</span>
			</div>

			<div style="position: absolute;top:286px;left:607px">
				<span >#</span>
			</div>

			<div style="position: absolute;top:336px;left:459px">
				<span >#</span>
			</div>

			<div style="position: absolute;top:386px;left:49px">
				<span >#</span>
			</div>

			<div style="position: absolute;top:436px;left:115px">
				<span >#</span>
			</div>

		</div>
		
							`,
        answer: [
          "recycle our",
          "make it necessary everywhere",
          "recycling their rubbish",
          "recycling",
          "save the planet",
          "definitely worth",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P76-E3",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Work in pairs. Practise the dialogue.
							`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:25px;position: relative;">
			<img src="img/FriendsPlus/Page76/12.jpg" width="1200px" />
		</div>
		
							`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P76-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		Work in pairs. You want to start a competition on making environment-friendly
		products at your school. Think of three reasons why it would be a good idea. Then
		compare your ideas with the class.
							`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:25px;position: relative;">
			<img src="img/FriendsPlus/Page76/14.jpg" />
		</div>
		
							`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P76-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Read the situation, prepare and practise a new dialogue. Use the key phrases and the
		dialogue in exercise 1 to help you.
							`,
        color: "rgb(220,30,116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left:200px;margin-top:25px;position: relative;">
			<img src="img/FriendsPlus/Page76/15.jpg" />
			<img src="img/FriendsPlus/Page76/13.jpg" />
		</div>
		<div style="margin-top:25px;position: relative;">
			<img src="img/FriendsPlus/Page76/12.jpg" width="1200px" />
		</div>
		
		
							`,
        answer: [],
      },
    ],
  },
};

export default json;
