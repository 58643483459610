import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E1",
    audio: "Audios/2.13.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			<headphone name="2.13"></headphone >
			Complete the phrases with the words in the box. Then listen and check.
									`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
				<div class="container">
	
			<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a degree</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">good marks</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a lawyer</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">management</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a position</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">school</p>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">1</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">attend</span>
					<span style="font-size: 25px">university / college / # </span>
				</div>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">2</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">do</span>
					<span style="font-size: 25px">a course / an apprenticeship / # </span>
				</div>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">3</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">study</span>
					<span style="font-size: 25px">engineering / science / # </span>
				</div>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">4</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">train to be</span>
					<span style="font-size: 25px">an engineer / a physicist / # </span>
				</div>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">5</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">get</span>
					<span style="font-size: 25px">the right qualifications / a job / # </span>
				</div>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">
				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">6</span>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">apply for</span>
					<span style="font-size: 25px">a job / a course / # </span>
				</div>
			</div>
	
			
		
				
									`,
        answer: [
          "school",
          "a degree",
          "management",
          "a lawyer",
          "good marks",
          "a position",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E2",
    audio: "Audios/2.14.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
			<headphone name="2.14"></headphone >
			Match the words in the box with photos A–J. Listen and check. Which careers
			would you like to do a course in? Rate them from
			1 to 10 (1 = like the least, 10 = like the most).

									`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
		<div class="container">
			<div style="margin-top: 50px"><img src="img/FriendsPlus/Page62/12.jpg" width="1200px"/></div>

	
			<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">construction</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">engineering</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">entertainment</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">journalism</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">IT</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">law</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">management</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">nursing</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">science</p>
			  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">teaching</p>
			</div>
			<h1 style="margin-top: 30px">Answers</h1>


			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">A</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">B</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">C</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">D</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">E</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">F</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">G</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">H</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">I</span>
					<span style="font-size: 25px">#</span>
				</div>

				<div>
					<span style="font-size: 25px;font-weight: bold;margin-right: 10px;">J</span>
					<span style="font-size: 25px">#</span>
				</div>

			</div>
				
									`,
        answer: [
          "nursing",
          "science",
          "engineering",
          "teaching",
          "management",
          "construction",
          "entertainment",
          "journalism",
          "IT",
          "law",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 310,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
		Find out the job titles of the people who do
		the jobs in exercise 2. Can you add other jobs
		and people to the list?<br/>
		<i style="font-weight: bold;font-size: 18px;">nursing – nurse</i>
									`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			
		<div class="container">
		
			<h1 style="margin-top: 30px">Answers</h1>


			<div style="display: flex; flex-wrap: wrap; gap: 10px;margin-top:30px">

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#,</span>
				</div>

				<div>
					<span style="font-size: 25px">#</span>
				</div>


		</div>
				
									`,
        answer: [
          "nursing - nurse",
          "science - scientist",
          "engineering - engineer",
          "teaching - teacher",
          "management - manager",
          "construction - builder",
          "entertainment - entertainer",
          "journalism - journalist",
          "IT - IT worker",
          "law - lawyer",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E4",
    audio: "Audios/2.15.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 730,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
		<headphone name="2.15"></headphone >
		Listen and write true or false. Correct the false sentences.
									`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:40px">

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<span >Aisha wants to be an engineer</span>
				<p>#</p>
			</div>

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<span >Aisha thinks that she will get good marks in her exams.</span>
				<p>#</p>
			</div>

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<span >Mia used to want to be a nurse.</span>
				<p>#</p>
			</div>

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">4</span>
				<span >Mia doesn’t want to go to university.</span>
				<p>#</p>
			</div>

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">5</span>
				<span >Max’s mum says he should be a professional sleeper.</span>
				<p>#</p>
			</div>

			<div style="font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">6</span>
				<span >Max has applied for an apprenticeship in computing.</span>
				<p>#</p>
			</div>





		</div>
		
				
									`,
        answer: [
          "false (She wants to do something she'll enjoy, like a surfing instructor.)",
          "false (She doesn't think she'll get good enough marks.)",
          "false (Her friends say she should be a nurse.)",
          "false (She wants to go to university.)",
          "true",
          "false (He hasn't applied yet.)",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E5",
    audio: "Audios/2.15.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E5answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 830,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
		<headphone name="2.15"></headphone >
		Listen again and decide who is the most certain about their future. What words
		help you to identify this?
									`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<h1 style="margin-top: 30px">Answers</h1>
		<div><textarea id="0" rows="2"></textarea></div>
									`,
        answer: [
          "Aisha is the most certain. She uses verbs like have to, must, mustn't, which express certainty.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U6-P62-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in groups. Ask and answer questions 1–5 about the jobs in exercises 2 and 3. Then tell the rest of the class which jobs are most popular in your group.

        `,
        color: "rgb(248,129,37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top:30px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">1</span>
            <span style="font-size: 25px">Which careers involve helping people?
			</span>
        </div>
		
        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">2</span>
            <span style="font-size: 25px">Which careers are more physical?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">3</span>
            <span style="font-size: 25px">Which careers can make you more money?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">4</span>
            <span style="font-size: 25px">Which careers involve studying for many
			years at university?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">5</span>
            <span style="font-size: 25px">Which job would you like to do?</span>
        </div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
