import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P51-E3",
    audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 400,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name='2.03'></headphone >
        Watch or listen to five people talking about their opinions on different ages. Which person
        thinks they will get a good job?
        `,
        color: "rgb(27, 191, 215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top: 20px; background-color: rgb(125,167,216); display: flex; justify-content: center; align-items: center; border: 1px solid rgb(0,103,180); margin-bottom:40px;min-width: 950px ">
    
        <div style="position: relative; text-align:center; margin-right:40px; flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Mitchell </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Emma </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Zara </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>
    
        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Joe </p>
          <p style="position: absolute; top: 0;right: -20px; width: 1px; height: 100%;  border-right: 1px solid rgb(0,103,180);" ></p>
        </div>

        <div style="position: relative; text-align:center; margin-right:40px;flex: 1">
          <p style="color:white; font-size: 20px; margin-top:10px">Paul </p>
          
        </div>
      </div>




        <div style="margin-top: 20px;">
          <h3>ANSWER</h3>
          <p>#</p>
        </div>

        
        `,
        answer: ["Mitchell thinks he'll get a good job."],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P51-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Work in pairs. Complete the questions with phrases from this page and your own ideas. Then ask and answer the questions. Use the key phrases in your answers.
        `,
        color: "rgb(125,167,216)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page51/8.jpg" style="margin-left: 200px;margin-bottom: 40px;" /></div>

        <div style="display: flex; flex-wrap: wrap;">
          <p style="font-size: 20px;">
            <span style="font-weight: bold;font-size: 20px; margin-right: 20px;">1</span>
            <span style="font-size: 20px;">What's the best age to</span>
            <span style="font-size: 20px; margin-right: 20px;">.................................?</span>
            
          </p>
        </div>

        <div style="display: flex; flex-wrap: wrap;">
          <p style="font-size: 20px;">
            <span style="font-weight: bold;font-size: 20px; margin-right: 20px;">2</span>
            <span style="font-size: 20px;">Do you think that you'll</span>
            <span style="font-size: 20px; margin-right: 20px;">.................................? one day? Why (not)?</span>
          </p>
        </div>

        <div style="display: flex; flex-wrap: wrap;">
          <p style="font-size: 20px;">
            <span style="font-weight: bold;font-size: 20px; margin-right: 20px;">3</span>
            <span style="font-size: 20px;">What do you want to do when</span>
            <span style="font-size: 20px; margin-right: 20px;">.................................?</span>
          </p>
        </div>

        <div style="display: flex; flex-wrap: wrap;">
          <p style="font-size: 20px;">
            <span style="font-weight: bold;font-size: 20px; margin-right: 20px;">4</span>
            <span style="font-size: 20px;">4 When do you think you'll</span>
            <span style="font-size: 20px; margin-right: 20px;">.................................?</span>
          </p>
        </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FP8-SB-demo-2023-U4-P51-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Look again at the age groups in exercise 1. Which one do you think is the best age? Why?",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  }
};

export default json;
