import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P11-E3",
    audio: "Audios/1.07.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/anwerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 180,
    titleQuestion: [
      {
        num: "3",
        title: `
					<videoimage></videoimage> <audioimage name='1.07'></audioimage>
					Watch or listen to five people talking about their interests. 
					Match the speakers with the topics. Which speaker spends a lot of money on his / her interest?
				`,
        color: "rgb(3, 103, 181)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='margin-top: 20px; display: flex; gap: 30px;'>
              <div style='flex: 1;'><img src='img/FriendsPlus/Page11/E3/1.jpg' width=100% /></div>
							<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 1px solid rgb(3, 103, 181);'>
								<div><i style='border-bottom: 2px solid gray;'>Answer:</i></div>
								<div style='display: flex; '><div style='width: 200px;'>Max:</div>#</div>
								<div style='display: flex; '><div style='width: 200px;'>Elizabeth:</div>#</div>
								<div style='display: flex; '><div style='width: 200px;'>Mitchell:</div>#</div>
								<div style='display: flex; '><div style='width: 200px;'>Yana:</div>#</div>
								<div style='display: flex; '><div style='width: 200px;'>Joe:</div>#</div>
								<div>Which speaker spends a lot of money on his / her interest? <input id='5' width=280px /></div>
							</div>
            </div>
        `,
        answer: [
          "sports",
          "music",
          "fashion",
          "social media",
          "comics",
          "Speaker 3 (Mitchell) / Speaker 3 / Mitchell",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P11-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 180,
    titleQuestion: [
      {
        num: "4",
        title: `
					Work in pairs. Ask and answer questions about the topics in exercise 3 using the key phrases. 
					Tell the class what your partner’s favourite interest is.
				`,
        color: "rgb(3, 103, 181)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='margin-top: 20px; display: flex; gap: 30px;'>
              <div style='flex: 1;'><img src='img/FriendsPlus/Page11/E4/1.jpg' width=100% /></div>
              <div style='flex: 2;'><img src='img/FriendsPlus/Page11/E4/2.jpg' width=100% /></div>
              <div style='flex: 2;'><img src='img/FriendsPlus/Page11/E4/3.jpg' width=100% /></div>
							</div>
            </div>
        `,
        answer: [
          "sports",
          "music",
          "fashion",
          "social media",
          "comics",
          "Speaker 3 (Mitchell) / Speaker 3 / Mitchell",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FP8-SB-demo-2023-U1-P11-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    finished: {
      text: "Use the key phrases to write a questionnaire for your class about their free time and interests.",
    },
    questions: [
      {
        title: `
          <div style='margin-block: 30px;'>
            <finished></finished>
          </div>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
