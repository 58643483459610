import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E1/Key/answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: ["distance", "food", "memory", "nose", "smell"],
        borderColor: "rgb(219, 46, 57)",
        width: 525,
      },
    ],
    inputSize: 120,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Read the article. Then complete the headings for paragraphs A–C with the words in the box.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintBox id='0'></hintBox>

					<div style='margin-top: 20px; display: flex; gap: 20px; align-items: start;'>
						<div style='flex:2;'><img src='img/FriendsPlus/Page20/E1/1.jpg' width='100%' /></div>

						<div style='flex: 1; border: 2px solid rgb(203, 226, 158); padding: 10px; border-radius: 10px;'>
							<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>A</span>
									<div>Scent and #</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>B</span>
									<div>Training a #</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>C</span>
									<div>Life without #</div>
								</div>
							</div>
						</div>
					</div>
				`,
        answer: ["memory", "nose", "smell"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E2",
    audio: "Audios/1.15.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E2/Key/answerKey.png",
    component: T6v2,
    maxLength: 35,
    inputSize: 40,
    checkUppercase: true,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
      // textTransform: "uppercase",
    },
    selectStyle: {
      color: "#00ADFE",
      width: 40,
      textAlign: "center",
      fontSize: 23,
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.15'></headphone > 
					Read and listen to the article again and write <i>true</i> or <i>false</i>. Correct the false sentences.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div><i style='font-size:20px; color: rgb(152,198,204)'>*If the sentence is true (T), rewrite the full sentence.</i></div><br>
					<div style='display: flex; gap: 10px; flex-direction: column; font-size: 23px'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div style='display: flex'>Helen Keller had a good sense of smell. <select id='5' margin-left='20px'></select></div>
							</div>
              <div><input id='0' width='750px' textAlign='left'></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div style='display: flex'>She says there’s a connection between smells and memory. <select id='6' margin-left='20px'></select></div>
							</div>
              <div><input id='1' width='750px' textAlign='left'></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div style='display: flex'>A person with a normal sense of smell can become a professional perfumer. <select id='7' margin-left='20px'></select></div>
							</div>
              <div><input id='2' width='750px' textAlign='left'></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div style='display: flex'>James Bell failed his first ‘smell test’ at the perfume company. <select id='8' margin-left='20px'></select></div>
							</div>
              <div><input id='3' width='750px' textAlign='left'></div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>5</span>
								<div style='display: flex'>Lucy could smell everything when she was a baby. <select id='9' margin-left='20px'></select></div>
							</div>
              <div><input id='4' width='750px' textAlign='left'></div>
						</div>
					</div>
				`,
        answer: [
          "Helen Keller had a good sense of smell.",

          "She says there's a connection between smells and memory.",

          `You must start with a superior sense of smell.`,

          `He passed the test.`,

          `She has been anosmic since birth.`,
          "T",
          "T",
          "F",
          "F",
          "F",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",
    component: T6v2,
    inputSize: 180,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					Find synonyms in the text for the words below.
				`,
        color: "rgb(219, 46, 57)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 20px; display: flex; gap: 20px; align-items: start;'>
						<div style='flex:2;'><img src='img/FriendsPlus/Page20/E1/1.jpg' width='100%' /></div>

						<div style='flex: 1; border: 2px solid rgb(203, 226, 158); padding: 10px; border-radius: 10px;
							display: flex; gap: 20px; flex-direction: column;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>1</span>
									<div>incredible #</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>2</span>
									<div>identify #</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>3</span>
									<div>artificial #</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>4</span>
									<div>organic #</div>
								</div>
						</div>
					</div>
				`,
        answer: ["extraordinary", "recognise", "synthetic", "natural"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E4",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 180,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `

					Use a dictionary to check the meaning of the words in <span style='color: rgb(0, 173, 238);'>blue</span> in the text.
				`,
        color: "rgb(219, 46, 57)",
        prefix: { text: "VOCABULARY PLUS" },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page20/E1/1.jpg' width='80%' /></div>
				`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E5",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: [
          "I love the smell of banh xeo. It really reminds me of my grandma’s house. It brings back memories of visiting her house when I was little.",
        ],
        borderColor: "rgb(219, 46, 57)",
        arrow: true,
        position: 1,
        width: 600,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Talk about which objects and places smell best to you. Which of them bring back memories?
				`,
        color: "rgb(219, 46, 57)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintbox id='0'></hintbox>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
