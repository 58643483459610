import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P73-E1',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page73/key/E1answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '1',
        title: `
			Complete each sentence with one word from the article on page 72.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-top:30px"><img src="img/FriendsPlus/Page72/9.jpg" width="1200px"></div>
		<h1 style="margin-top: 30px">Answers</h1>
			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<span>
					The doctors told him that he # walk or play the guitar
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<span>
				Daniel told the boy’s family that he #
				donate all his money to the boy’s surgery
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<span>
				The police said that he # to
				return a bag with $42,000 in it to the
				owner
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">4</span>
				<span>
				The team said that people in need still #
				access to food and resources.
				</span>		
			</div>		
	
				
				
		
				`,
        answer: ["couldn't", 'would', 'decided', 'had'],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U6-P73-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page73/key/E2answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title: `
        Choose the correct options to complete the rules.
					`,
        color: 'rgb(28,190,215)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 30,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
          fontSize: '32px',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: ['is / was', 'that / this', 'past_tenses / future_tenses'],
        answers: ['0-4', '2-0', '1-0'],
        initialValue: {},
      },
      Layout: `
	  <div style="position: relative; ">
	  	<img src="img/FriendsPlus/Page73/12.jpg" width="800px" />

		  <div style="position: absolute;top:130px;left:553px">
		  	<input id='0' type='Circle' style="letter-spacing: 10px;"/>
		  </div>

		  <div style="position: absolute;top:214px;left:316px">
		  	<input id='1' type='Circle' style="letter-spacing: 10px;"/>
		  </div>

		  <div style="position: absolute;top:393px;left:58px">
		  	<input id='2' type='Circle' style="letter-spacing: 10px;"/>
		  </div>
		
	  </div>
          

          

				`,
    },
  },
  3: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P73-E3',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page73/key/E3answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
		Study the rules, then complete the following sentences.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px"><img src="img/FriendsPlus/Page73/13.jpg" /></div>

		<h1 style="margin-top: 30px">Answers</h1>
			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<span>
				'You have to rest for 3 months.'
				The doctors told me that I # to
			   rest for 3 months. 
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<span>
				'Honey can prevent the growth of bacteria.' Some scientists said that honey # <br/>
				<span style="margin-left: 35px;">
				prevent the growth of bacteria.
				
				</span>
 
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<span>
				'I am raising some money for my poor students.'<br/>
				<span style="margin-left: 35px;">
					She told me that she # some money for her poor students
				</span>
				</span>		
			</div>		

			<div style="margin-top:30px;">
				<span style="font-weight: bold;margin-right: 10px;">4</span>
				<span>
				'When you spread kindness, you may feel good about yourselves.' <br/>
				<span style="margin-left: 35px;">
					Our teacher said that when we # kindness, we # feel good about
					ourselves
				</span>
				<span>
				
				</span>		
			</div>		
	
				
				
		
				`,
        answer: ['had', 'could', 'was raising', 'spread', 'might'],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P73-E4',
    audio: '',
    video: '',
    // recorder: true,
    exerciseKey: 'img/FriendsPlus/Page73/key/E4answerKey.png',
    component: T6v2,
    // maxLength: 1,
    inputSize: 650,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      marginTop: '20px',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
		Find and correct the mistakes in the sentences.
				`,
        color: 'rgb(28,190,215)',
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="margin-top:30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">1</span>
				<span>
				He said that it isn’t a good idea. 
				</span>
				<p>#</p>		
			</div>		

			<div style="margin-top:30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">2</span>
				<span>
				They didn’t tell me that they will visit
				Hạ Long Bay.
				</span>
				<p>#</p>		
			</div>		

			<div style="margin-top:30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">3</span>
				<span>
				The dentist told that his tooth needed filling.
 
				</span>
				<p>#</p>		
			</div>		

			<div style="margin-top:30px;font-size: 25px;">
				<span style="font-weight: bold;margin-right: 10px;">4</span>
				<span>
				Liên said that she may be absent from the
				meeting. 
				</span>
				<p>#</p>		
			</div>		

				`,
        answer: [
          "He said that it wasn't a good idea.",
          "They didn't tell me that they would visit Hạ Long Bay.",
          'The dentist told him that his tooth needed filling.',
          'Liên said that she might be absent from the meeting.',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FP8-SB-demo-2023-U7-P73-E5',
    audio: '',
    video: '',
    // recorder: true,
    // exerciseKey: '',
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 650,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      marginTop: '20px',
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '5',
        title: `
		Work in pairs. Look at the photos. Take turns to report what your
		mother said to you in these photos.
				`,
        color: 'rgb(28,190,215)',
        prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px;margin-top:30px"><img src="img/FriendsPlus/Page73/14.jpg" /></div>

				`,
        answer: [],
      },
    ],
  },
};

export default json;
