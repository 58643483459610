import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P18-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/E1/Key/answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
					Look at photos 1-5 and match them with the senses in the box.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
						max-width: 420px; padding: 10px 15px; border-radius: 20px; border: 2px solid rgb(0, 104, 180);'>
						<span>hearing</span><span>sight</span><span>smell</span>
						<span>taste</span><span>touch</span>	
					</div>

					<div style='margin-top: 20px; display: flex;'>
						<div style='flex: 1;'><img src='img/FriendsPlus/Page18/E1/1.jpg' width='80%' /></div>
						<div style='flex: 1;'>
							<div style='display: flex; gap: 20px; flex-direction: column;
								border: 2px solid rgb(0, 104, 180); width: fit-content; padding: 20px; border-radius: 10px;'>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Photo 1</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Photo 2</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Photo 3</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Photo 4</span>
									<div>#</div>
								</div>
								<div style='display: flex; gap: 10px;'>
									<span style='font-weight: bold;'>Photo 5</span>
									<div>#</div>
								</div>
							</div>
						</div>
					</div>
				`,
        answer: ["sight", "touch", "hearing", "smell", "taste"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P18-E1",
    audio: "Audios/1.13.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/answerKey.png",
    component: TB2,
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='1.13'></headphone > 
					Read the magazine quiz and complete the table with the words in <span style='color: rgb(0, 173, 238);'>blue</span>. Listen and check.`,
        color: "rgb(0, 104, 180)",
      },
    ],
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page18/E2/1.jpg' width='70%' /></div>
				`,
        answer: [],
      },
    ],
    tb2Style: { width: 800 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Hearing",
        content: ["sound", "#", "#", "#"],
        answers: ["", "tone-deafness", "have a good ear", "listen"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Sight",
        content: ["#", "#", "#", "#"],
        answers: ["look", "colour-blindness", "see", "watch"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Smell",
        content: ["#"],
        answers: ["smell"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Taste",
        content: ["#"],
        answers: ["taste"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Touch",
        content: ["#", "#", "#"],
        answers: ["feel", "hold", "losing sensation"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P18-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page18/E3/Key/answerKey.png',
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Do the quiz. Which sense is the most important for you? Compare your result with a partner's.
				`,
        color: "rgb(0, 104, 180)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div><img src='img/FriendsPlus/Page18/E2/1.jpg' width='80%' /></div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
