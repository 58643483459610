import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P25-E1',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
		component: T6v2,
		// inputSize: 35,
		textareaStyle: { width: 400, fontSize: '24px' },
		titleQuestion: [
			{
				num: '1',
				title: `
					Read the magazine advert and last year’s
					winning entry about the Mekong Delta. What
					do you have to do to enter the competition?
					What’s the prize? What do we learn about
					the residents in Southern Việt Nam?
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='position: relative;'>
						<div><img src='img/FriendsPlus/Page25/E1/1.jpg' width='90%' /></div>

						<div style='position: absolute; top: 270px; left: 0px;'>
							<div>
								<div style='font-weight: bold;'>What do you have to do to enter<br /> the competition?</div>
								<div><textarea id="0" rows="4" ></textarea></div>
							</div>
							<div>
								<div style='font-weight: bold;'>What’s the prize?</div>
								<div><textarea id="1" rows="2" ></textarea></div>
							</div>
							<div>
								<div style='font-weight: bold;'>What do we learn about the residents<br /> in Southern Việt Nam?</div>
								<div><textarea id="2" rows="3" ></textarea></div>
							</div>
						</div>
					</div>
        `,
				answer: [
					`To enter the competition, you have to describe the sights, tastes, sounds and feelings that visitors to your country can experience.`,
					`The prize is a two-week trip to Australia.`,
					`We learn that the residents in southern Việt Nam are very warm. | We learn that the residents in southern Viet Nam are very warm.`,
				],
			},
		],
	},
	2: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P25-E2',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
		component: T6v2,
		isHiddenCheck: true,
		inputSize: 450,
		hintBox: [
			{
				src: ['absolutely', 'really', 'very'],
				borderColor: 'rgb(0, 169, 79)',
				width: 350,
			},
		],
		titleQuestion: [
			{
				num: '2',
				title: `
					Find the words in the box in the 
					<i>Experience the Mekong Delta in Southern 
					Việt Nam</i> text. What type of word (adjective, 
					noun or verb) do they always come before?
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='margin-top: 20px; display: flex; gap: 20px;'>
						<div>
							<hintBox id='0'></hintBox>
							<div style='margin-top: 20px; font-weight: bold;'>What do you have to do to enter<br /> the competition?</div>
							<div>#</div>
						</div>
						<div><img src='img/FriendsPlus/Page25/E2/1.jpg' width='100%' /></div>
					</div>
        `,
				answer: [`They always come before adjectives.`],
			},
		],
	},
	3: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P25-E3',
		audio: '',
		video: '',
		recorder: true,
		exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
		component: T6v2,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true,
		inputSize: 230,
		titleQuestion: [
			{
				num: '3',
				title: `
					Study the examples and answer the questions.
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div style='display: flex; gap: 100px'>
						<div style='display: flex; flex-direction: column;'>
							<div style='display: flex; gap: 15px;'>
								<div>very soft</div>
								<div style='margin-top: -5px;'><img src='img/FriendsPlus/Page25/E3/1.jpg' width='70%' /></div>
							</div>
							<div style='display: flex; gap: 15px;'>
								<div>really soft</div>
								<div style='margin-top: -5px;'><img src='img/FriendsPlus/Page25/E3/1.jpg' width='70%' /></div>
							</div>
							<div style='display: flex; gap: 15px;'>
								<div>absolutely soft</div>
								<div style='margin-top: -2px;'><img src='img/FriendsPlus/Page25/E3/2.jpg' width='70%' /></div>
							</div>
						</div>
						<div style='display: flex; flex-direction: column;'>
							<div style='display: flex; gap: 15px;'>
								<div>very delicious</div>
								<div style='margin-top: -5px;'><img src='img/FriendsPlus/Page25/E3/1.jpg' width='70%' /></div>
							</div>
							<div style='display: flex; gap: 15px;'>
								<div>really delicious</div>
								<div style='margin-top: -5px;'><img src='img/FriendsPlus/Page25/E3/1.jpg' width='70%' /></div>
							</div>
							<div style='display: flex; gap: 15px;'>
								<div>absolutely delicious</div>
								<div style='margin-top: -2px;'><img src='img/FriendsPlus/Page25/E3/2.jpg' width='70%' /></div>
							</div>
						</div>
					</div>

					<div style='margin-top: 20px; display: flex; gap: 10px; flex-direction: column;'>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>Which adjective is an extreme adjective (it describes a strong opinion): <i>soft</i> or <i>delicious</i>?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>Do we use <i>very</i> with normal or extreme adjectives?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>3</span>
								<div>Can we use <i>really</i> with both normal and extreme adjectives?</div>
							</div>
							<div>#</div>
						</div>
						<div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>4</span>
								<div>What other intensifiers can we use with adjectives? Find examples in the text.</div>
							</div>
							<div>#, #</div>
						</div>
					</div>
        `,
				answer: [`delicious`, `normal adjectives`, `yes`, `extremely`, `quite`],
			},
		],
	},
	4: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P25-E4',
		audio: '',
		video: '',
		recorder: true,
		component: T6v2,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true,
		inputSize: 230,
		titleQuestion: [
			{
				num: '4',
				title: `
					Read the key phrases and find them in the 
					<i>Experience the Mekong Delta in Southern 
					Việt Nam</i> text. Think of how to complete 
					the first two phrases about your town.
				`,
				color: 'rgb(0, 169, 79)',
			},
		],
		questions: [
			{
				title: `
					<div><img src='img/FriendsPlus/Page25/E4/1.jpg' width='50%' /></div>
        `,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Unit 2',
		id: 'FP8-SB-demo-2023-U2-P25-E5',
		audio: '',
		video: '',
		recorder: true,
		component: T6v2,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true,
		inputSize: 230,
		titleQuestion: [
			{
				num: '5',
				title: `
					Follow the steps in the writing guide.
				`,
				color: 'rgb(0, 169, 79)',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],
		questions: [
			{
				title: `
					<div><img src='img/FriendsPlus/Page25/E5/1.jpg' width='45%' /></div>
        `,
				answer: [],
			},
		],
	},
}

export default json
