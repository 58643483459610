import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E1",
    audio: "Audios/2.23.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
		<headphone name="2.23"></headphone >
			Match the adjectives with explanations 1–8. Listen and check.
					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">confident</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">generous</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">heroic</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">honest</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">organised</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">patient</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">sympathetic</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">thoughtful</p>
			</div>
			<p style="font-size: 25px">Someone who …</p>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>remembers your birthday</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>is not shy</span>
			</div>
			
			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>listens to your problems</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>does very brave things</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>plans things carefully</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">6</span>
				<span>gives people more than they need
				</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">7</span>
				<span>waits for others</span>
			</div>

			<div style="display: flex; flex-wrap: wrap;font-size: 25px">
				<span style="font-weight: bold; margin-right: 10px">8</span>
				<span>returns a lost wallet</span>
			</div>
			<h1 style="margin-top: 30px">Answers</h1>
			
			<div style="display: flex; flex-wrap: wrap;gap:25px;font-size: 25px">

				<div>
					<span style="font-weight: bold; margin-right: 10px">1</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">2</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">3</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">4</span>
					<span>#</span>
				</div>

				<div>

					<span style="font-weight: bold; margin-right: 10px">5</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">6</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">7</span>
					<span>#</span>
				</div>

				<div>
					<span style="font-weight: bold; margin-right: 10px">8</span>
					<span>#</span>
				</div>


			</div>
					
					
			
					`,
        answer: [
          "thoughtful",
          "confident",
          "sympathetic",
          "heroic",
          "organised",
          "generous",
          "patient",
          "honest",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E2",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		Which words can you use to describe
		yourself? Which adjectives do you think are
		important qualities for these people?
					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;margin-left:300px">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a teacher</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a friend</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a mother</p>
					  
			</div>
			
					`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E3",
    audio: "Audios/2.24.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 950,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		<headphone name="2.24"></headphone >
		Listen to three students having a discussion with their teacher. What are they
		talking about?

					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<h1 style="margin-top: 30px">Answer</h1>
		<p style="font-size: 25px">#</p>
			
					`,
        answer: [
          "They're talking about change-makers - people who make the world a better place.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E4",
    audio: "Audios/2.24.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		<headphone name="2.24"></headphone >
		Listen again. Write the adjectives the students use to describe each person.

					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap;font-size: 25px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span>Robert Lee</span>
		</div>

		<div style="display: flex; flex-wrap: wrap;font-size: 25px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>Edwin Sabuhoro</span>
		</div>

		<div style="display: flex; flex-wrap: wrap;font-size: 25px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>Marilyn Price</span>
		</div>

		<h1 style="margin-top: 30px">Answers</h1>

		<div style="display: flex; flex-wrap: wrap;font-size: 25px;margin-top:25px">
			<span style="font-weight: bold; margin-right: 10px">1</span>
			<span>Robert:#</span>
		</div>

		<div style="display: flex; flex-wrap: wrap;font-size: 25px;margin-top:25px">
			<span style="font-weight: bold; margin-right: 10px">2</span>
			<span>Edwin:#</span>
		</div>

		<div style="display: flex; flex-wrap: wrap;font-size: 25px;margin-top:25px">
			<span style="font-weight: bold; margin-right: 10px">3</span>
			<span>Marilyn:#</span>
		</div>
			
					`,
        answer: [
          "clever, thoughtful",
          "brave, heroic, fair, sympathetic, generous",
          "kind, patient",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E5",
    audio: "Audios/2.24.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 1100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		<headphone name="2.24"></headphone >
		Answer the questions. Listen again and check.
					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex;flex-direction: column; flex-wrap: wrap;font-size: 25px">

			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>Where does Robert Lee get the unwanted food from?</span>
				<p>#</p>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>Why is it a clever idea?</span>
				<p>#</p>
			</div>

			<div>

				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>What animals was Edwin Sabuhoro trying
				to protect?</span>
				<p>#</p>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>How does his eco-tour company provide a
				good solution to problems in Rwanda?</span>
				<p>#</p>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>What opportunities do the bike trips give
				to city kids?</span>
				<p>#</p>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">6</span>
				<span>In what countries can you find Marilyn
				Price’s programmes?</span>
				<p>#</p>
			</div>
			
			
		</div>

		

		
					`,
        answer: [
          "from New York restaurants.",
          "It helps people in need, and it also stops restaurants from wasting food.",
          "mountain gorillas.",
          "It gives work to people and protects the wildlife.",
          "They can see different places, make new friends, and become healthier and more confident.",
          "the USA, Canada, Israel and Africa",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E6",
    audio: "Audios/2.25.mp3",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 170,
    stylesTextInput: {
      // background: 'transparent',
      border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
		<headphone name="2.25"></headphone >
		<span style="color: rgb(248,129,37)">PRONUNCIATION: Diphthongs</span>
Identify the diphthong sounds and put the
words in the correct columns. Listen and repeat.
					`,
        color: "rgb(248,129,37)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="border: 2px solid rgb(248,129,37); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">now</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">change</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">chose</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">kind</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">wildlife</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">wasting</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">mountain</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">homeless</p>
			</div>
			<table style="border: 1px solid rgb(248,129,37);margin-top:30px;margin-left:140px">
				<thead style="text-align:center">
					<th style="border: 1px solid rgb(248,129,37);">/aɪ/</th>
					<th style="border: 1px solid rgb(248,129,37);">/aʊ/</th>
					<th style="border: 1px solid rgb(248,129,37);">/əʊ/</th>
					<th style="border: 1px solid rgb(248,129,37);">/eɪ/</th>
				</thead>
				<tbody>
					<tr>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					</tr>

					<tr>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					<td style="border: 1px solid rgb(248,129,37);">#</td>
					</tr>

					
				</tbody>
			</table>

		
					`,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P74-E7",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 1100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "7",
        title: `
		Work in groups. Using the ideas below, talk about people you admire. Use
		adjectives in exercise 1.
					`,
        color: "rgb(248,129,37)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex;flex-direction: column; flex-wrap: wrap;font-size: 25px">

			<div>
				<span style="font-weight: bold; margin-right: 10px">•</span>
				<span> a member of your family</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">•</span>
				<span> a person during the COvID-19 pandemic</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px">•</span>
				<span> a person at your school</span>
			</div>

		</div>
		<div style="margin-left: 200px;margin-top:30px"><img src="img/FriendsPlus/Page74/14.jpg" /></div>

		

		
					`,
        answer: [],
      },
    ],
  },
};

export default json;
